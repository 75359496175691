import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import jQuery from "jquery";

const Footer = () => {
  useEffect(() => {
    (function ($) {
      "use strict";

      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $(".back-to-top").fadeIn("slow");
        } else {
          $(".back-to-top").fadeOut("slow");
        }
      });
    })(jQuery);
  }, []);

  return (
    <Fragment>
      <div
        className="container-fluid bg-dark text-light py-5 wow fadeInUp"
        data-wow-delay="0.3s"
        style={{ marginTop: "-75px" }}
      >
        <div className="container pt-5">
          <div className="row g-5 pt-4">
            {/* <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Quick Links</h3>
              <div className="d-flex flex-column justify-content-start">
                <Link to="/" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>Home
                </Link>
                <Link to="/aboutus" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>About
                  Us
                </Link>
                <Link to="/careservice" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>Our
                  Services
                </Link>
                <Link to="/blog" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>
                  Blog
                </Link>
                <Link to="/contact" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>Contact
                  Us
                </Link>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <h3 className="text-white mb-4">Useful Links</h3>
              <div className="d-flex flex-column justify-content-start">
                <Link to="/gdpr" className="text-light mb-2">
                  {" "}
                  <i className="bi bi-arrow-right text-primary me-2"></i>GDPR
                  Policy
                </Link>{" "}
                <Link to="/aboutus" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>About
                  us
                </Link>
                <Link to="/careservice" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>Care
                  Services
                </Link>
                <Link to="/blog" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>
                  Blog
                </Link>
                <Link to="/contact" className="text-light mb-2">
                  <i className="bi bi-arrow-right text-primary me-2"></i>Contact
                  Us
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h3 className="text-white mb-4">Get In Touch</h3>
              <p className="mb-2">
                <i className="bi bi-geo-alt text-primary me-2"></i>Excellence
                Care Ltd Suite 1A
                <br />
                <span style={{ marginLeft: "23px" }}> Broadway Chambers</span>
                <br />
                <span style={{ marginLeft: "23px" }}> Pitsea, Essex</span>
                <br />
                <span style={{ marginLeft: "23px" }}> SS13 3AS</span>
              </p>
              <p className="mb-2">
                <i className="bi bi-envelope-open text-primary me-2"></i>
                info@excellence-care.co.uk{" "}
              </p>
              <p className="mb-0">
                <i className="bi bi-telephone text-primary me-2"></i> 012689
                88500, 012682 03030
              </p>
            </div>
            <div className="col-lg-4 col-md-6">
              <h3 className="text-white mb-4">Follow Us</h3>
              <div className="d-flex">
                <a
                  style={{ backgroundColor: "#" }}
                  className="btn btn-lg btn-primary btn-lg-square rounded me-2"
                  href="https://www.facebook.com/ExcellenceCareUK/"
                >
                  <i className="fab fa-facebook-f fw-normal"></i>
                </a>
                <a
                  style={{ backgroundColor: "#" }}
                  className="btn btn-lg btn-primary btn-lg-square rounded me-2"
                  href="https://www.linkedin.com/company/excellence-care/?viewAsMember=true "
                >
                  <i className="fab fa-linkedin-in fw-normal"></i>
                </a>
                <a
                  style={{ backgroundColor: "#" }}
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href="https://www.instagram.com/excellencecare.uk/ "
                >
                  <i className="fab fa-instagram fw-normal"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-light py-4"
        style={{ background: "#051225" }}
      >
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6 text-center text-md-start">
              <p className="mb-md-0">
                &copy;{" "}
                <a className="text-white border-bottom" href="#">
                  Excellence Care
                </a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>

        {/*<a href="#"
                 className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"        >
                    <i className="bi bi-arrow-up"></i></a>
    */}
      </div>
      {/* <button
        style={{
          position: "fixed",
          bottom: "10px",
          right: "10px",
          color: "white",
          backgroundColor: "#843A77",
          //display: "none"
        }}
        type="button"
        class="btn btn-primary btn-floating btn-lg"
        id="back-to-top"
        onClick={() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        <i className="fas fa-arrow-up"></i>
      </button> */}
    </Fragment>
  );
};

export default Footer;
