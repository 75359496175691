import { Fragment, useState, useEffect } from "react";
import about from "../img/pl.jpg";

const Preventing = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="pfb"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Preventing Falls{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Preventing Falls{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Preventing Falls{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Our Care, Your Rights: Adhering to Legal Standards{" "}
                </h1>
              </div>

              <p className="mb-4">
                Preventing falls is a critical aspect of providing safe and
                effective care, especially for elderly and disabled individuals.
                <br />
                <br />
                • Overview of the importance of fall prevention: Explain why
                fall prevention is so important, and how it can help people
                maintain their independence and quality of life.
                <br />
                <br />
                • Risk factors for falls: Provide information on the risk
                factors that can contribute to falls, such as balance and gait
                problems, vision issues, medication side effects, and
                environmental hazards.
                <br />
                <br />
                • Strategies for preventing falls: Outline some of the
                strategies that can help prevent falls, such as exercise and
                physical therapy, regular vision exams, medication reviews, and
                modifications to the home environment (e.g. removing tripping
                hazards, installing grab bars).
                <br />
                <br />
                • Tips for staying safe: Provide practical tips that individuals
                can use to reduce their risk of falling, such as wearing sturdy
                shoes, using assistive devices (e.g. canes, walkers), and taking
                care when navigating stairs and other obstacles.
                <br />
                <br />
                I • mportance of assessment: Stress the importance of fall risk
                assessments, and encourage people to speak with their healthcare
                provider if they are concerned about their risk of falling.
                <br />
                <br />
                • Resources: Provide links to additional resources, such as
                informational websites, videos, and training materials, that can
                help people learn more about fall prevention and safety.
                <br />
                <br />
                Overall, should emphasize the importance of fall prevention,
                while providing actionable tips and resources that people can
                use to stay safe and avoid falls. Regenerate response
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Preventing;
