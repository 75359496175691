import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import re from "./img/re.jpg";
import s3 from "./img/s3.jpeg";
import s1 from "./img/sl.jpg";
import $ from "jquery";
import owlCa from "owl.carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./css/blog.css";

const Service = () => {
  const options = {
    loop: true,
    margin: 45,
    items: 2,
    autoplay: true,
    nav: true,
  };

  return (
    <Fragment>
      <div className="serviceClass">
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-5">
                <div className="section-title mb-4">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Our Care Services
                  </h5>
                  <h1 className="display-5 mb-0">
                    Innovative Services for a Better Tomorrow
                  </h1>
                </div>
                <p className="mb-4">
                  We understand that caring for a loved one can be a challenging
                  and stressful task. That's why we're here to provide
                  compassionate, professional, and reliable home care services.
                  Our goal is to help seniors and those with disabilities live
                  comfortable, safe, and dignified lives.
                </p>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  <a style={{ color: "#091e3e" }} href="tel: +01268988500">
                     012689 88500{" "}
                  </a>
                </h1>
              </div>

              <div className="col-lg-7">
                <OwlCarousel
                  className="owl-theme"
                  {...options}
                  style={{
                    navText: [
                      '<i class="bi bi-arrow-left"></i>',
                      '<i class="bi bi-arrow-right"></i>',
                    ],
                    responsive: {
                      0: {
                        items: 1,
                      },
                      768: {
                        items: 2,
                      },
                    },
                  }}
                >
                  <div className="price-item pb-4">
                    <a href="#SLive">
                      <div className="position-relative">
                        <img
                          className="img-fluid rounded-top"
                          src={s1}
                          alt=""
                        />
                        <Link to="/careservice">
                          {" "}
                          <div
                            className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                            style={{ zIndex: 2 }}
                          >
                            <h2
                              style={{ width: "max-content" }}
                              className="btn btn-primary  py-2 px-4  position-absolute top-100 start-50 translate-middle"
                            >
                              Supported Living
                            </h2>
                          </div>
                        </Link>
                      </div>
                    </a>
                  </div>
                  <div className="price-item pb-4">
                    <div className="position-relative">
                      <img className="img-fluid rounded-top" src={s3} alt="" />
                      <Link to="/careservice">
                        <div
                          className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                          style={{ zIndex: 2 }}
                        >
                          <h2 className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle">
                            Domiciliary
                          </h2>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="price-item pb-4">
                    <div className="position-relative">
                      <img className="img-fluid rounded-top" src={re} alt="" />
                      <Link to="/careservice">
                        <div
                          className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                          style={{ zIndex: 2 }}
                        >
                          <h2 className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle">
                            Residential
                          </h2>
                        </div>
                      </Link>
                    </div>
                  </div>
                </OwlCarousel>
                {/*<div
                                    className="owl-carousel price-carousel wow zoomIn"
                                    data-wow-delay="0.9s"

                                >

                                    <div className="price-item pb-4">
                                        <a href="#SLive" >
                                            <div className="position-relative">
                                                <img
                                                    className="img-fluid rounded-top"
                                                    src={s1}
                                                    alt=""
                                                />
                                                <div
                                                    className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                                                    style={{ zIndex: 2 }}
                                                >
                                                    <h2 style={{ width: "max-content" }} className="btn btn-primary  py-2 px-4  position-absolute top-100 start-50 translate-middle"
                                                    >Supported Living</h2>
                                                </div>
                                            </div>
                                        </a>

                                    </div>


                                    <div className="price-item pb-4">
                                        <div className="position-relative">
                                            <img
                                                className="img-fluid rounded-top"
                                                src={s3}
                                                alt=""
                                            />
                                            <div
                                                className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                                                style={{ zIndex: 2 }}
                                            >
                                                <h2 className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle"
                                                >Domiciliary</h2>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="price-item pb-4">
                                        <div className="position-relative">
                                            <img
                                                className="img-fluid rounded-top"
                                                src={re}
                                                alt=""
                                            />
                                            <div
                                                className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
                                                style={{ zIndex: 2 }}
                                            >
                                                <h2 className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle"
                                                >Residential</h2>
                                            </div>
                                        </div>
                                    </div>
        </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Service;
