import { Fragment, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./css/blog.css";

// import "./careservice.scss";

const Career = () => {
  const options = {
    loop: true,
    dots: false,
    nav: false,
    margin: 45,
    items: 1,
    autoplay: true,
  };

  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="maisbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Career{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Career{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div
          className="container-fluid mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          id="AAPoint"
          style={{ background: "#fff" }}
        >
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-6 py-5">
                <div className="py-5">
                  <h5 className="display-5 text-black mb-4">
                    Unlock Your Potential: Build a Rewarding Career with Us
                    <br />
                    <br />
                    Also Apply through:
                    <br />
                    <a href="https://www.linkedin.com/company/excellence-care/?viewAsMember=true">
                      {" "}
                      <img
                        style={{ height: "40px", paddingRight: "10px" }}
                        src={require("./img/link.jpg")}
                      />
                    </a>
                    <a href="https://uk.indeed.com/cmp/Excellence-Care-Ltd">
                      <img
                        style={{
                          height: "40px",
                          paddingLeft: "10px",
                          cursor: "pointer",
                        }}
                        src={require("./img/ind.jpg")}
                      />
                    </a>
                    {/* <img
                      style={{ height: "40px", paddingLeft: "10px" }}
                      src={require("./img/mon.jpg")}
                    /> */}
                  </h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="appointment-form h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  <h1 className="text-white mb-4">Apply Now</h1>
                  <form>
                    <div className="row g-3">
                      <div className="col-12 col-sm-6">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          placeholder="Your Name"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          type="email"
                          className="form-control bg-light border-0"
                          placeholder="Your Email"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          type="number"
                          className="form-control bg-light border-0"
                          placeholder="Phone Number"
                          style={{ height: "55px" }}
                        />
                      </div>

                      <div className="col-12 col-sm-6">
                        <select
                          className="form-select bg-light border-0"
                          style={{ height: "55px" }}
                        >
                          <option selected>Opening Jobs</option>
                          <option value="1">Service 1</option>
                          <option value="2">Service 2</option>
                          <option value="3">Service 3</option>
                        </select>
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          type="file"
                          className="form-control bg-light border-0"
                          placeholder="Attach File"
                          style={{ height: "55px" }}
                        />
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-dark w-100 py-3"
                          type="submit"
                        >
                          Apply Now{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5
        style={{ textAlign: "center", marginBottom: "30px" }}
        className="text-primary  text-uppercase"
      >
        What our people say{" "}
      </h5>

      <OwlCarousel
        className="owl-theme"
        {...options}
        style={{
          navText: [
            '<i class="bi bi-arrow-left"></i>',
            '<i class="bi bi-arrow-right"></i>',
          ],
        }}
      >
        <div
          style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          className="testimonial-item text-center text-black"
        >
          <p className="careerClass">
            I have been in the care sector for fourteen years. I started as a
            carer and with my robust experience, willingness to learn and
            improve my skills and thee qualifications i gained, I am now able to
            display competence with my leadership and management skills. I
            always had a positive attitude towards my work where I listen and
            emphatise with my staff and service users and i need to take action
            or resolution when needed. I develop a positive atmosphere and I
            make sure that staff feels that I am part of the team. I ensure that
            I praise good work and encourage my team to praise each other
            enabling them to gain and develop the knowledge and skills needed
            for the job and to accelerate to their goals in an ambient
            environment.
          </p>
          <hr className="mx-auto w-25" />
          <h4 className="text-black mb-0">Rishi -Registered Manager</h4>
        </div>

        <div
          style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          className="testimonial-item text-center text-black"
        >
          <p className="careerClass">
            At Excellence Care, I've experienced remarkable growth in a
            supportive and innovative environment. It's not just a job; it's a
            fulfilling career.
          </p>
          <hr className="mx-auto w-25" />
          <h4 className="text-black mb-0">Bellinda -Support Worker</h4>
        </div>

        <div
          style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          className="testimonial-item text-center text-black"
        >
          <p className="careerClass">
            Starting as a fresher at Excellence-Care, I found incredible support
            from my co-workers. Looking back, I see tremendous personal and
            professional development. Grateful to be part of a nurturing
            community.
          </p>
          <hr className="mx-auto w-25" />
          <h4 className="text-black mb-0"> Prince Asidu -Support Worker</h4>
        </div>

        <div
          style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          className="testimonial-item text-center text-black"
        >
          <p className="careerClass">
            As a dedicated manager at Excellence-Care, I am passionate about
            cultivating a strong team. Through shared experiences and education,
            we empower each other to achieve excellence together. Join our
            journey of growth and collaboration at Excellence-Care.
          </p>
          <hr className="mx-auto w-25" />
          <h4 className="text-black mb-0">Roopesh -Manager</h4>
        </div>
      </OwlCarousel>
    </Fragment>
  );
};

export default Career;
