import { Fragment, useState, useEffect } from "react";
import about from "../img/divsid.jpg";
import "./about.scss";

const Welcoming = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="welcoming"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Welcoming Diversity{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Welcoming Diversity{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  WE ENCOURAGE DIVERSITY{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Celebrating differences, building unity{" "}
                </h1>
              </div>

              <p className="mb-4">
                At Excellence Care, we believe that diversity is not just a
                buzzword; it's the very essence of who we are and how we provide
                exceptional care to our residents. We recognize that each
                individual brings a unique set of experiences, perspectives, and
                cultures, and we strive to create an environment where everyone
                feels valued, respected, and celebrated.
                <br />
                <br />
                Our commitment to diversity extends to every aspect of our care
                home, from the recruitment and training of our staff to the
                activities and events we offer our residents. We believe that a
                diverse workforce fosters creativity, innovation, and a deeper
                understanding of the needs of our residents.
                <br />
                <br />
                We also encourage our residents to embrace their cultural
                heritage and share their unique traditions, enriching our
                community and fostering a more vibrant and stimulating
                environment for everyone.
              
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Welcoming;
