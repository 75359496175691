import { Fragment, useState, useEffect } from "react";

const Contact = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  window.scrollTo(0, 0);

  return (
    <Fragment>
      <div
        style={{ position: "relative", top: !isMobile ? "100px" : "0px" }}
        class="container-fluid bg-primary py-5 hero-header mb-5"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1 class="display-3 text-white animated zoomIn">Contact Us</h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5"
        id="CContact"
      >
        <div className="container">
          <div className="row g-5">
            <div
              className="col-xl-4 col-lg-6 wow slideInUp"
              data-wow-delay="0.1s"
            >
              <div className="bg-light rounded h-100 p-5">
                <div className="section-title">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Contact Us
                  </h5>
                  <h1 className="display-6 mb-4">Feel Free To Contact Us</h1>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                  <div className="text-start">
                    <h5 className="mb-0">Our Office</h5>
                    <span>
                      Excellence Care Ltd
                      <br /> Suite 1A Broadway Chambers Pitsea,
                      <br />
                      Essex, SS13 3AS
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
                  <div className="text-start">
                    <h5 className="mb-0">Email Us</h5>
                    <span> info@excellence-care.co.uk</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
                  <div className="text-start">
                    <h5 className="mb-0">Call Us</h5>
                    <span> 01268 988500</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 wow slideInUp"
              data-wow-delay="0.3s"
            >
              <form>
                <div className="row g-3">
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Name"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="number"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Number"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Email"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="City"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control border-0 bg-light px-4 py-3"
                      rows="5"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="col-xl-4 col-lg-12 wow slideInUp"
              data-wow-delay="0.6s"
            >
              <iframe
                className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.503912799238!2d0.48361087700967986!3d51.55899510693241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8c43259900001%3A0xfadbdc6555310cf8!2sExcellence%20Care%20Ltd!5e0!3m2!1sen!2sin!4v1700209372331!5m2!1sen!2sin"
                frameBorder="0"
                style={{ minHeight: "400px", border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
