import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// import about from "../img/wav.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import wav1 from "../img/clay1.jpg";
import wav2 from "../img/wick1.jpg";
import wav3 from "../img/wick3.jpg";

import "./care.scss";

const Eastern = () => {
  const options = {
    loop: true,
    margin: 45,
    items: 2,
    autoplay: true,
    nav: true,
  };
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="chb"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Eastern Lodge{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Eastern Lodge{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container">
            {/* cont1 */}
            <div className="row g-5">
              <div className="col-lg-5">
                <div className="section-title mb-4">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Eastern Lodge{" "}
                  </h5>
                </div>
                <p className="mb-4">
                  Nestled amidst a vibrant neighborhood in London N22 7DD,
                  Eastern Road offers residents a harmonious blend of natural
                  beauty and well-regarded healthcare facilities, creating an
                  idyllic setting for a fulfilling lifestyle. <br />
                  <br />
                  For those seeking tranquility amidst nature, the area is
                  blessed with an abundance of green spaces, including Woodside
                  Park, Cranbrook Park, and White Hart Lane Recreation Ground,
                  all located within a short distance. These parks provide ample
                  opportunities for leisurely strolls, invigorating picnics, and
                  outdoor recreation. Additionally, Russell Park, another
                  verdant oasis, is also close by, inviting residents to relax
                  and de-stress amidst its peaceful surroundings. Whether you're
                  seeking a quiet spot to read a book, practice yoga, or simply
                  enjoy the serenity of nature, these parks offer a welcome
                  respite from the urban environment.
                  <br />
                  <br />
                  Whittington Hospital, a well-established healthcare facility,
                  is situated within a 2-mile radius, providing a broader range
                  of medical services. For specialized care, Finchley Memorial
                  Hospital and North Middlesex University Hospital are both
                  within a 3-mile drive, offering specialized expertise and
                  advanced treatment options. <br />
                  <br />
                  Overall, Eastern Road offers a unique blend of urban
                  convenience and natural beauty, making it an ideal place to
                  call home
                </p>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  01268 988500{" "}
                </h1>
              </div>

              <div className="col-lg-7">
                <OwlCarousel
                  className="owl-theme"
                  {...options}
                  style={{
                    navText: [
                      '<i class="bi bi-arrow-left"></i>',
                      '<i class="bi bi-arrow-right"></i>',
                    ],
                    responsive: {
                      0: {
                        items: 1,
                      },
                      768: {
                        items: 2,
                      },
                    },
                  }}
                >
                  <div className="price-item pb-4">
                    <a href="#SLive">
                      <div className="position-relative">
                        <img
                          className="img-fluid rounded-top"
                          src={wav1}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>

                  <div className="price-item pb-4">
                    <div className="position-relative">
                      <img
                        className="img-fluid rounded-top"
                        src={wav2}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="price-item pb-4">
                    <div className="position-relative">
                      <img
                        className="img-fluid rounded-top"
                        src={wav3}
                        alt=""
                      />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>

            {/* cont2 */}
            <div style={{ marginTop: "20px" }} className="row g-5">
              <div className="col-lg-5">
                <div className="mb-4">
                  <h5 className="text-primary text-uppercase">
                    Nominated Individual/ Care
                    <br /> & Compliance Manager{" "}
                  </h5>
                </div>
                <img
                  style={{ height: "300px" }}
                  src={require("../img/team-11.jpeg")}
                />
                <h2
                  style={{ marginTop: "20px" }}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  {/* Mr Kaveerajsing Baungally{" "} */}
                </h2>
              </div>
              <div className="col-lg-5">
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  Michelle{" "}
                </h1>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  01268 988500{" "}
                </h5>
                <p>
                  I serve as the Nominated Individual/Care & Compliance Manager
                  at Excellence Care. My name is Michelle, and I am available
                  for contact via phone at <b>01268 988500</b> or through email
                  at <b>info@excellence-care.co.uk</b> Feel free to reach out to
                  me for any inquiries or assistance related to our care
                  services.
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  Make Appointment
                </Link>
                <a
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    marginLeft: "15px",
                    border: "1px solid green",
                  }}
                  className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                  href="https://api.whatsapp.com/send?phone=447402951877&text=Hello%20Excellence%20Care!"
                >
                  WhatsApp
                  <i
                    style={{
                      color: "green",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                    className="fab fa-whatsapp fw-normal"
                  ></i>
                </a>
              </div>
            </div>
            <div style={{ marginTop: "20px" }} className="row g-5">
              <div className="col-lg-5">
                <div className="section-title mb-4">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Registered Manager{" "}
                  </h5>
                </div>
                <img
                  style={{ height: "300px" }}
                  src={require("../img/rishinew.png")}
                />
                <h2
                  style={{ marginTop: "20px" }}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  {/* Mr Kaveerajsing Baungally{" "} */}
                </h2>
              </div>
              <div className="col-lg-5">
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  Mr Kaveerajsing Baungally
                </h1>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  01268 988500{" "}
                </h5>
                <p>
                  I am the Registered Manager and my name is Mr Kaveerajsing
                  Baungally but I like to be called ‘Rishi’ You can contact me
                  by phone on <b>01268 988500</b> Or Email:
                  <b> info@excellence-care.co.uk</b>
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  Make Appointment
                </Link>
                <a
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    marginLeft: "15px",
                    border: "1px solid green",
                  }}
                  className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                  href="https://api.whatsapp.com/send?phone=447402951877&text=Hello%20Excellence%20Care!"
                >
                  WhatsApp
                  <i
                    style={{
                      color: "green",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                    className="fab fa-whatsapp fw-normal"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Eastern;
