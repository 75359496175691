import { Fragment, useState, useEffect } from "react";
import about from "../img/ld.jpg";
import "./careservice.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Mais = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="maisbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Profound and Multiple Learning Disability (PMLD){" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Profound and Multiple Learning Disability (PMLD){" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}
        <div style={{}} className="container">
          <div className="row g-6">
            <div className="section-title mb-4">
              {" "}
              <h5 className="position-relative d-inline-block text-primary text-uppercase">
                Profound and Multiple<br/> Learning Disability (PMLD){" "}
              </h5>
            </div>

            <div className="">
              <p style={{ paddingTop: "20px" }} className="mb-4">
                Excellence Care also has an abundance of experience in being
                able to support people who have a profound and multiple learning
                disability. These individuals are significantly affected in
                their ability to be able to communicate and be as independent as
                possible. We ensure that people are supported as much as
                possible to take control of their own care and daily needs,
                however we are there to support people with the following, where
                this can be an identified need:
                <br />
                <br />
                • Supporting people with their personal care – washing and oral
                hygiene
                <br />
                <br />
                • Supporting people to plan, shop for their groceries, prepare
                and cook their meals daily and support them to make healthy
                choices where this is possible
                <br />
                <br />
                • Enable people to be involved in making their own decisions and
                to do things they enjoy daily
                <br />
                <br />
                • Support people to be able to communicate – Makaton, PEC for
                example
                <br />
                <br />• Support people to be as independent as possible
              </p>
              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Mais;
