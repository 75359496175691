import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import about from "../img/about.jpg";
const About = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);

  return (
    <Fragment>
      <div
        style={{ position: "relative", top: !isMobile ? "100px" : "0px" }}
        class="container-fluid bg-primary py-5 hero-header mb-5"
        className="abbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              About Us
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              About
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        class="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  OUR JOURNEY{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Providing Quality Care with Dignity since 2008{" "}
                </h1>
              </div>

              <p className="mb-4">
                Excellence Care provides an array of support for those who need
                it, ranging from supported living to domiciliary and residential
                care. We recognize that everyone's needs are unique, and our
                team of qualified and experienced care professionals is
                committed to providing tailored support to ensure that those in
                our care receive the best possible care in a comfortable and
                safe environment.
                <br />
                <br /> Our services encompass assistance with daily tasks,
                providing companionship, and enabling individuals to maintain as
                much independence as possible. We are committed to delivering
                the highest quality of care, prioritizing dignity, respect, and
                choice.
                <br />
                <br />
                Understanding that care is a deeply personal matter, we strive
                to ensure our clients feel respected and valued. We also extend
                support to families, keeping them well-informed about the care
                we provide and ensuring they can always rely on us for advice
                and guidance.
                <br />
                <br />
                At our organisation, we understand the importance of providing
                high-quality care and support. We are committed to putting our
                clients at the heart of everything we do, and we strive to
                ensure our services are tailored to each individual's needs.
              </p>
              <div style={{ cursor: "pointer" }} className="row g-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                  <h5 className="mb-3">
                    <Link to="/excellencecarevision">
                      {" "}
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Our Vision & Mission{" "}
                    </Link>
                  </h5>
                  <h5 className="mb-3">
                    <Link to="/awards">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Our Awards
                    </Link>
                  </h5>
                  <h5 className="mb-3">
                    <Link to="/team">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Our Team
                    </Link>
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.6s">
                  <h5 className="mb-3">
                    <Link to="/welcomingdiversity">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      We Encourage Diversity{" "}
                    </Link>
                  </h5>
                  <h5 className="mb-3">
                    <Link to="/testimonial">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Testimonial
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>

          <div className="">
            <h2
              style={{
                marginTop: "60px",
                marginBottom:'40px',
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
                color: "#F28C28",
                textAlign: "center",
              }}
            >
              "Excellence Care is where residents can enjoy the simple pleasure
              of life , like a warm cup of tea"
            </h2>
          </div>

          {/* <h5
            style={{ marginTop: "50px" }}
            className="position-relative d-inline-block text-primary text-uppercase"
          >
            Equality of Opportunity and Service Delivery{" "}
          </h5>
          <br />
          <p style={{ marginTop: "20px" }}>
            Excellence Care aims to provide services to individuals based on
            their needs and as such there may be occasion where the service
            supports individuals that transition from one or more categories
            within a spectrum of individual needs to another. <br />
            <br />
            Excellence Care will not discriminate when providing services to an
            individual based on any criteria other than the legal requirements
            of our Registration with the Care Quality Commission (CQC) and our
            ability to provide services. Currently Excellence Care Stafford
            provide services to individuals under 18 years of age or service
            users with a primary diagnosis of Dementia.
          </p> */}
          <Link
            to="/contact"
            className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
            data-wow-delay="0.6s"
          >
            Make Appointment
          </Link>
          <a
            style={{
              backgroundColor: "#fff",
              color: "black",
              marginLeft: "15px",
              border: "1px solid green",
            }}
            className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
            href="https://wa.link/k4ywmn"
          >
            WhatsApp
            <i
              style={{ color: "green", marginLeft: "10px", fontSize: "20px" }}
              className="fab fa-whatsapp fw-normal"
            ></i>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
