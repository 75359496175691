import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./blog.css";

const BlogPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);

  return (
    <Fragment>
      <div
        style={{ position: "relative", top: !isMobile ? "100px" : "0px" }}
        class="container-fluid bg-primary py-5 hero-header mb-5"
        className="blogb"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              Blog{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Blog{" "}
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        class="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      ></div>

      <div class="blog">
        <div class="container">
          <div class="row">
            {/* blog1 */}
            <div class="col-lg-4">
              <div class="blog-item">
                <div class="blog-img">
                  <img src={require("../img/blog1.jpg")} alt="Image" />
                </div>
                <div class="blog-text">
                  <h3>
                    <a href="#">
                      Choosing the Right Care Option for Your Loved Ones
                    </a>
                  </h3>
                  <p>
                    As our loved ones grow older, it becomes increasingly
                    important to ensure that they receive the care and support
                    they need to maintain their quality of life.{" "}
                  </p>
                </div>
                <div class="blog-meta">
                  <p>
                    <i class="fa fa-user"></i>
                    <a href="">Rishi</a>
                  </p>
                  {/* <p><i class="fa fa-comments"></i><a href="">15 Comments</a></p> */}
                </div>
              </div>
            </div>
            {/* blog2 */}
            <div class="col-lg-4">
              <div class="blog-item">
                <div class="blog-img">
                  <img src={require("../img/blog2.jpg")} alt="Image" />
                </div>
                <div class="blog-text">
                  <h3>
                    <a href="#">
                      The Benefits of Companion Care for Elderly Individuals
                    </a>
                  </h3>
                  <p>
                    As we age, it's common for us to experience social isolation
                    and loneliness, which can have a negative impact on our
                    physical and mental health.
                  </p>
                </div>
                <div class="blog-meta">
                  <p>
                    <i class="fa fa-user"></i>
                    <a href="">Roopesh</a>
                  </p>
                  {/* <p><i class="fa fa-comments"></i><a href="">15 Comments</a></p> */}
                </div>
              </div>
            </div>
            {/* blog3 */}
            <div class="col-lg-4">
              <div class="blog-item">
                <div class="blog-img">
                  <img src={require("../img/blog3.jpg")} alt="Image" />
                </div>
                <div class="blog-text">
                  <h3>
                    <a href="#">
                      The Importance of Physical Activity in Elderly Care
                    </a>
                  </h3>
                  <p>
                    Physical activity is crucial for maintaining good health and
                    wellbeing, especially as we age.t's important for elderly
                    individuals to engage in regular physical activity to stay
                    active.
                  </p>
                </div>
                <div class="blog-meta">
                  <p>
                    <i class="fa fa-user"></i>
                    <a href="">Admin</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="row">
                    <div class="col-12">
                        <ul class="pagination justify-content-center">
                            <li class="page-item disabled"><a class="page-link" href="#">Previous</a></li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">Next</a></li>
                        </ul> 
                    </div>
                </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default BlogPage;
