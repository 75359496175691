import { Fragment, useState, useEffect } from "react";
import about from "../img/domcare.jpg";
import "./careservice.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const WhatisDom = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showPer, setShowPer] = useState(false);
  const [showMed, setShowMed] = useState(false);

  const showPeri = () => {
    setShowPer(!showPer);
  };
  const showMedi = () => {
    setShowMed(!showMed);
  };
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="dombg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Domiciliary Care{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Domiciliary Care{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Domiciliary Care{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Compassionate care, right at home{" "}
                </h1>
              </div>

              <p className="mb-4">
                Domiciliary care, often referred to as home care, is a type of
                care service that enables individuals to maintain their
                independence and well-being in their own familiar surroundings.
                It offers a wide range of services, such as housekeeping and
                meal preparation, to specialized medical care for individuals
                with chronic health conditions.
                <br />
                <br />
                Our caregivers will assist you in maintaining your freedom at
                home by providing help with practical tasks, personal care, or
                day-to-day housework. They can also help ensure that you take
                your medication at the correct intervals and accompany you on
                daily trips into town or other outings.
                <br />
                <br />
                Here are some daily tasks that our domiciliary caregivers can
                assist you with: <br />
                <br />
                • Preparing meals (cooking) <br />
                <br />• General housekeeping – such as cleaning and doing
                laundry <br />
                <br />
                • Bathing and personal care <br />
                <br />
                • Administering and overseeing medication (helping with
                medication) <br />
                <br />
                • Dressing <br />
                <br />
                • Doing your shopping <br />
                <br />
                • Using the toilet <br />
                <br />
                • Brushing your hair <br />
                <br />• Gardening
              </p>
            </div>

            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }} className="container">
          <div className="row g-12">
            <div className="">
              <p style={{ paddingTop: "20px" }} className="mb-4">
                Here are alternative terms and phrases to describe different
                types of domiciliary care:
                <div style={{ marginTop: "20px" }}>
                  <h5
                    style={{
                      border: "1px solid #ebebeb",
                      padding: "10px",
                      background: "#ebebeb",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    className="text-primary"
                    onClick={showPeri}
                  >
                    {showPer ? (
                      <i className="fa fa-chevron-down text-primary me-3"></i>
                    ) : (
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                    )}{" "}
                    Personal Care
                    {showPer ? (
                      <p
                        style={{
                          color: "#414141",
                          fontSize: "18px",
                          fontWeight: "lighter",
                          padding: "20px",
                          marginLeft: "20px",
                        }}
                      >
                        {" "}
                        Personal care is important for both physical and mental
                        health. These activities can range from basic hygiene
                        practices like bathing and brushing our teeth to more
                        complex tasks like grooming our hair. <br />
                        <br />
                        If you or a loved one needs help or support with
                        personal grooming, ensuring that you take medication at
                        the correct intervals, daily trips into town, or if you
                        need a friend or companion, a personal care worker can
                        provide all of these services and more. Our caregivers
                        will assist you in maintaining your freedom at home.
                        They can support you with practical tasks, personal
                        care, or day-to-day housework. <br />
                        <br />
                        Here are some daily tasks that our personal caregivers
                        can assist you with: <br />
                        <br />
                        ● Bathing and personal care <br />
                        <br />
                        ● Dressing <br />
                        <br />● Brushing your hair <br />
                        <br />
                        If you believe you require Personal care to preserve
                        your comfort of living independently in your own home,
                        don’t hesitate to contact us.
                      </p>
                    ) : (
                      ""
                    )}
                  </h5>
                  <h5
                    style={{
                      border: "1px solid #ebebeb",
                      padding: "10px",
                      background: "#ebebeb",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    className="text-primary"
                    onClick={showMedi}
                  >
                    {showPer ? (
                      <i className="fa fa-chevron-down text-primary me-3"></i>
                    ) : (
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                    )}{" "}
                    Medical Care
                    {showMed ? (
                      <p
                        style={{
                          color: "#414141",
                          fontSize: "18px",
                          fontWeight: "lighter",
                          padding: "20px",
                          marginLeft: "20px",
                        }}
                      >
                        {" "}
                        Medical care is the diagnosis, treatment, and prevention
                        of disease, injury, and other health problems. It is
                        provided by a wide range of healthcare professionals.{" "}
                        <br />
                        <br />
                        Our caregivers will assist you in maintaining your
                        medical care at home. Here are some daily tasks that our
                        medical caregivers can assist you with: <br />
                        <br />
                        ● Preparing nutritious meals (cooking) <br />
                        <br />
                        ● Administering and overseeing medication (helping with
                        medication)
                        <br />
                        <br />
                        If you believe you require Medical care to preserve your
                        comfort of living independently in your own home, don’t
                        hesitate to contact us.
                      </p>
                    ) : (
                      ""
                    )}
                  </h5>
                </div>
              </p>

              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhatisDom;
