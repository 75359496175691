import React, { Fragment } from "react";

const Newsletter = () => {
  return (
    <Fragment>
      <div
        className="container-fluid position-relative pt-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ zIndex: 1 ,marginBottom:'60px'}}
      >
        {/* <div className="container">
          <div className="bg-primary p-5">
            <form className="mx-auto" style={{ maxWidth: "600px" }}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control border-white p-3"
                  placeholder="Your Email"
                />
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#F28C28" }}
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div> */}
        {/* </div> */}
      </div>
    </Fragment>
  );
};

export default Newsletter;
