import { Fragment, useState, useEffect } from "react";
import "./about.scss";

const Awards = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="awardb"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Awards{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Awards{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div style={{ marginTop: "10px" }} className="container">
          <div className="row g-6">
            <h5 className="position-relative d-inline-block text-primary text-uppercase">
              Discover the prestigious awards we've received{" "}
            </h5>
            <br />

            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "space-around",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div>
                <img
                  style={{ height: "120px", width: "auto", marginTop: "20px" }}
                  src={require("../img/adas.png")}
                  alt="adas"
                />
                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <b style={{ color: "#843a77" }}>
                    Excellent
                    <br /> <span class=" fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </b>
                </div>
              </div>
              <div>
                <img
                  style={{ height: "100px", width: "auto", marginTop: "40px" }}
                  src={require("../img/pamms.png")}
                  alt="adas"
                />{" "}
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  <b style={{ color: "#843a77" }}>
                    Excellent
                    <br /> <span class=" fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </b>
                </div>
              </div>
              <div>
                <img
                  style={{ height: "120px", width: "210px", marginTop: "40px" }}
                  src={require("../img/cqc1.png")}
                  alt="adas"
                />{" "}
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  <b style={{ color: "#843a77" }}>
                    Outstanding
                    <br /> <span class=" fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </b>
                </div>
                {/* <p style={{ marginTop: "0px" }}>
                  <b style={{ color: "#843a77" }}>Excellent </b>
                  <br />
                  <span class=" fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Awards;
