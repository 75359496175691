import { Fragment, useState, useEffect } from "react";
import "./team.css";

const Team = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);

  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        className="teambg container-fluid bg-primary py-5  mb-5"
      >
        <div className="row py-3">
          <div className="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              className="display-3 text-white animated zoomIn"
            >
              {" "}
              Team{" "}
            </h1>
            <a href="" className="h4 text-white">
              Home
            </a>
            <i className="far fa-circle text-white px-2"></i>
            <a href="" className="h4 text-white">
              Team{" "}
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "0px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div class="team">
            <div className="section-title mb-4">
              <h5 className="position-relative d-inline-block text-primary text-uppercase">
                Team{" "}
              </h5>
              <h1 className="display-5 mb-0">
                {" "}
                Celebrating differences, building unity{" "}
              </h1>
            </div>
            <div class="container">
              <div class="row">
                {/* t1 */}
                <div
                  style={{
                    width: "450px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                  class=""
                >
                  <div class="team-item">
                    <div class="team-img">
                      <img
                        src={require("./img/team-1.jpeg")}
                        alt="Team Image"
                      />
                    </div>
                    <div class="team-text">
                      <h2>Rosie Kavanagh</h2>
                      <p>Director RGN/BscHons</p>
                      <div class="team-social">
                        <a href="">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="">
                          <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a href="">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <p style={{ textAlign: "center" }} className="mb-4">
                  "As a team, we are committed to going above
                  <br /> and beyond for our clients, and we take pride in the
                  work we do."
                </p>
              </div>
            </div>

            {/* t2 */}
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="team-item">
                  <div class="team-img">
                    <img src={require("./img/team-11.jpeg")} alt="Team Image" />
                  </div>
                  <div class="team-text">
                    <h2>Michelle</h2>
                    <p>Nominated Individual/ Care & Compliance Manager</p>
                    <div class="team-social">
                      <a href="">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <p style={{ textAlign: "center" }} className="mb-4">
                  "Working for this company has been a truly rewarding
                  experience, and I feel privileged to be part of such a
                  dedicated and compassionate team."
                </p>
              </div>
              {/* t3 */}
              {/* <div class="col-lg-4 col-md-6">
                <div class="team-item">
                  <div class="team-img">
                    <img src={require("./img/team-1.jpeg")} alt="Team Image" />
                  </div>
                  <div class="team-text">
                    <h2>Roopesh</h2>
                    <p>Chef Executive</p>
                    <div class="team-social">
                      <a href="">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <p style={{ textAlign: "center" }}>
                  {" "}
                  "Our company culture is built on a foundation of respect,
                  professionalism, and empathy."
                </p>
              </div> */}
              {/* t4 */}
              {/* <div class="col-lg-4 col-md-6">
                <div class="team-item">
                  <div class="team-img">
                    <img src={require("./img/team-1.jpeg")} alt="Team Image" />
                  </div>
                  <div class="team-text">
                    <h2>Thomas Olsen</h2>
                    <p>Chef Advisor</p>
                    <div class="team-social">
                      <a href="">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <p style={{ textAlign: "center" }}>
                  {" "}
                  "Our company culture is built on a foundation of respect,
                  professionalism, and empathy."
                </p>
              </div> */}
              {/* t5 */}
              {/* <div class="col-lg-4 col-md-6">
                <div class="team-item">
                  <div class="team-img">
                    <img src={require("./img/team-11.jpeg")} alt="Team Image" />
                  </div>
                  <div class="team-text">
                    <h2>Thomas Olsen</h2>
                    <p>Chef Advisor</p>
                    <div class="team-social">
                      <a href="">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <p style={{ textAlign: "center" }}>
                  {" "}
                  "Our company culture is built on a foundation of respect,
                  professionalism, and empathy."
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Team;
