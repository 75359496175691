import React, { Fragment } from "react";
import c2 from "./img//cqc1.png";
import { Link as ScrollLink, Element } from "react-scroll";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonial = () => {
  const options = {
    loop: true,
    dots: false,
    nav: false,
    margin: 45,
    items: 1,
    autoplay: true,
  };

  return (
    <Fragment>
      <div
        id="testimonial"
        className="container-fluid  bg-appointment my-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <OwlCarousel
                className="owl-theme"
                {...options}
                style={{
                  navText: [
                    '<i class="bi bi-arrow-left"></i>',
                    '<i class="bi bi-arrow-right"></i>',
                  ],
                }}
              >
                <div className="testimonial-item text-center text-white">
                  <p
                    style={{ fontFamily: "Jost,sans-serif" }}
                    className="testimonialClass fs-5"
                  >
                    I live at Grays House. Before i moved to Gray House,I was in
                    mental health hospital for 8 years , and then I moved to
                    Grays House. I was welcomed by Angela Sharman, Team Leader
                    at Grays House, along by other staff. I find i get lots of
                    support from Excellence Care.{" "}
                  </p>
                  <hr className="mx-auto w-25" />
                  <h4 className="text-white mb-0">
                    Alex Coleman{" "}
                    <span style={{ fontSize: "10px" }}>-Grays House</span>{" "}
                  </h4>
                </div>
                <div className="testimonial-item text-center text-white">
                  <p
                    style={{ fontFamily: "Jost,sans-serif" }}
                    className="testimonialClass fs-5"
                  >
                    I want to express my gratitude to the wonderful staff at
                    Excellence Care. They're not just caregivers; they're
                    family. The kindness and support I receive make this place
                    feel like home. Thank you for creating such a warm and
                    welcoming environment.
                  </p>
                  <hr className="mx-auto w-25" />
                  <h4 className="text-white mb-0">
                    Emma Rose{" "}
                    <span style={{ fontSize: "10px" }}>-Waverely Lodge</span>{" "}
                  </h4>
                </div>
                <div className="testimonial-item text-center text-white">
                  <p
                    style={{ fontFamily: "Jost,sans-serif" }}
                    className="testimonialClass fs-5"
                  >
                    I moved into Excellence Care feeling a bit unsettled, but
                    Manager Rishi and the team have been amazing. Their support
                    in every possible way has transformed my experience. Now, I
                    feel settled and this place is starting to feel like home.
                    Kudos to the wonderful team at Excellence Care!
                  </p>
                  <hr className="mx-auto w-25" />
                  <h4 className="text-white mb-0">
                    Florance Williams{" "}
                    <span style={{ fontSize: "10px" }}>-Waverely Lodge</span>
                  </h4>
                </div>
                <div className="testimonial-item text-center text-white">
                  <p
                    style={{ fontFamily: "Jost,sans-serif" }}
                    className="testimonialClass fs-5"
                  >
                    It was the best place I ever lived. I messed up. Even with
                    lots of warnings which I ignored,I had to leave. But now I
                    have been given a second chance even though I now live in
                    Walthamstow which I must admit it's better than Pitsea.
                  </p>
                  <hr className="mx-auto w-25" />
                  <h4 className="text-white mb-0">
                    Leon Watts{" "}
                    <span style={{ fontSize: "10px" }}>-Waverely Lodge</span>
                  </h4>
                </div>
              </OwlCarousel>
            </div>

            <div className="col-lg-5">
              <div
                className="  d-flex flex-column justify-content-center text-center p-5 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <img style={{ borderRadius: "23px" }} src={c2} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Testimonial;
