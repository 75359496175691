import { Fragment, useState, useEffect } from "react";
import about from "../img/excvision.jpg";

const Laws = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="laws"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Laws{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Laws{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Laws{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Our Care, Your Rights: Adhering to Legal Standards{" "}
                </h1>
              </div>

              <p className="mb-4">
                • General Data Protection Regulation (GDPR): The GDPR regulates
                how personal data is collected, processed, and stored. If your
                care website collects and processes personal data (e.g. names,
                addresses, and medical information), you will need to comply
                with the GDPR.
                <br />
                <br />
                • The Health and Social Care Act 2008 (Regulated Activities)
                Regulations 2014: This is the main piece of legislation that
                governs the provision of health and social care in England. It
                sets out the fundamental standards that care providers must meet
                to ensure that people receive safe, effective, and compassionate
                care.
                <br />
                <br />
                • The Care Quality Commission (CQC): The CQC is the independent
                regulator of health and social care in England. If your care
                website provides care services, you may be required to register
                with the CQC and comply with their regulations.
                <br />
                <br />
                • The Equality Act 2010: This act sets out the legal
                responsibilities of businesses and service providers to ensure
                that they do not discriminate against people on the basis of
                age, disability, gender reassignment, race, religion or belief,
                sex, sexual orientation, marriage and civil partnership, or
                pregnancy and maternity.
                <br />
                <br />
                • The Health and Safety at Work Act 1974: This act sets out the
                general duties of employers to ensure the health, safety, and
                welfare of their employees and other people who may be affected
                by their activities.
                <br />
                <br />• The Mental Capacity Act 2005: This act provides a legal
                framework for making decisions on behalf of people who lack the
                capacity to make decisions for themselves. If your care website
                provides services for people who lack capacity, you will need to
                comply with the Mental Capacity Act.
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Laws;
