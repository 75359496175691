import { Fragment, useState, useEffect } from "react";
import about from "../img/ress.jpg";
import "./careservice.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Residential = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showPer, setShowPer] = useState(false);
  const [showMed, setShowMed] = useState(false);

  const showPeri = () => {
    setShowPer(!showPer);
  };
  const showMedi = () => {
    setShowMed(!showMed);
  };
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="resibg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Residential Care{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Residential Care{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Residential Care{" "}
                </h5>
                <h1 className="display-5 mb-0"> Find a Home Away from Home </h1>
              </div>

              <p className="mb-4">
                Residential care is a type of long-term care that provides
                adults with 24-hour care and support in a safe and comfortable
                environment. Residential care facilities can range in size and
                level of care, from small, home-like settings to large, nursing
                homes.
                <br />
                <br />
                Residential care can provide many benefits for adults,
                including:
                <br />
                <br />
                • 24-hour care and support: adults can receive the care they
                need around the clock, from a team of trained professionals.
                <br />
                <br />
                • A safe and comfortable environment: Residential care
                facilities are designed to be safe and comfortable for adults.
                <br />
                <br />
                • Socialization and activities: Residential care facilities
                offer a variety of social activities and events to help adults
                stay engaged and connected to others.
                <br />
                <br />• Peace of mind for families: Families can have peace of
                mind knowing that their loved ones are being well cared for.
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px" }} className="container">
            <div className="row g-12">
              <div className="">
                <p style={{ paddingTop: "20px" }} className="mb-4">
                  Here are alternative terms and phrases that offers Residential
                  care:
                  <div style={{ marginTop: "20px" }}>
                    <h5
                      style={{
                        border: "1px solid #ebebeb",
                        padding: "10px",
                        background: "#ebebeb",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={showPeri}
                    >
                      {showPer ? (
                        <i className="fa fa-chevron-down text-primary me-3"></i>
                      ) : (
                        <i className="fa fa-chevron-right text-primary me-3"></i>
                      )}{" "}
                      Social Care
                      {showPer ? (
                        <p
                          style={{
                            color: "#414141",
                            fontSize: "18px",
                            fontWeight: "lighter",
                            padding: "20px",
                            marginLeft: "20px",
                          }}
                        >
                          {" "}
                          Social care is the provision of personal care and
                          support to adults who, due to age, disability or other
                          reasons, are unable to fully care for themselves.
                          <br />
                          <br /> We encourage and facilitate social interaction
                          among residents, organizing activities and events that
                          foster a sense of community and belonging.
                          <br />
                          <br /> Our caregivers are dedicated to providing
                          compassionate and personalised social care services
                          that enhance the well-being of our residents. They go
                          above and beyond to ensure that each individual feels
                          supported, valued, and empowered to live their best
                          life.
                        </p>
                      ) : (
                        ""
                      )}
                    </h5>
                    <h5
                      style={{
                        border: "1px solid #ebebeb",
                        padding: "10px",
                        background: "#ebebeb",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      className="text-primary"
                      onClick={showMedi}
                    >
                      {showPer ? (
                        <i className="fa fa-chevron-down text-primary me-3"></i>
                      ) : (
                        <i className="fa fa-chevron-right text-primary me-3"></i>
                      )}{" "}
                      Health & Well-Being Checks{" "}
                      {showMed ? (
                        <p
                          style={{
                            color: "#414141",
                            fontSize: "18px",
                            fontWeight: "lighter",
                            padding: "20px",
                            marginLeft: "20px",
                          }}
                        >
                          {" "}
                          At Excellence Care, we believe that regular health &
                          well-being checks are essential for maintaining the
                          best possible quality of life for our residents.
                          That's why we offer a comprehensive range of health &
                          well-being checks, tailored to the individual needs of
                          each resident. <br />
                          <br />
                          Our health & well-being checks include: <br />
                          Vital signs checks: We will check your loved one's
                          blood pressure, pulse, temperature, and respiration to
                          ensure they are within normal ranges.
                          <br />
                          <br />
                          Medication review: We will review your loved one's
                          medication regimen to ensure they are taking their
                          medications correctly and that there are no potential
                          interactions. Nutritional assessment: We will assess
                          your loved one's nutritional status to ensure they are
                          getting the nutrients they need to stay healthy.{" "}
                          <br />
                          <br />
                          Mental health assessment: We will assess your loved
                          one's mental health to identify any signs of
                          depression, anxiety, or other mental health
                          conditions. <br />
                          <br />
                          Functional assessment: We will assess your loved one's
                          ability to perform everyday tasks, such as bathing,
                          dressing, and eating. <br />
                          <br />
                          We believe that our health & well-being checks are an
                          important part of providing the best possible care for
                          our residents.
                        </p>
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                </p>

                <Link
                  to="/contact"
                  className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  Make Appointment
                </Link>
                <a
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    marginLeft: "15px",
                    border: "1px solid green",
                  }}
                  className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                  href="https://wa.link/k4ywmn"
                >
                  WhatsApp
                  <i
                    style={{
                      color: "green",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                    className="fab fa-whatsapp fw-normal"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Residential;
