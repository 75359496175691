import { Fragment, useState, useEffect } from "react";
import about from "../img/ld.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import "./careservice.scss";

const LearningDis = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="ldbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Learning Disability{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Learning Disability
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Learning Disability{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Nurturing a world where learning disabilities become
                  abilities.{" "}
                </h1>
              </div>

              <p className="mb-4">
                Learning disabilities are cognitive impairments that are
                characterized by challenges in daily living, such as learning,
                understanding, managing finances, socializing, and performing
                household chores.
                <br />
                <br />
                Individuals with learning disabilities may require additional
                support to acquire new skills, grasp complex information, and
                interact effectively with others. Our approach focuses on
                identifying individual needs and implementing strength-based
                strategies to empower each person to achieve their full
                potential.
              </p>
              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>

              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ marginTop: "80px" }} className="container">
          <div className="row g-6">
            <div className="">
              <h5 className="position-relative d-inline-block text-primary text-uppercase">
                Profound and Multiple Learning Disability (PMLD) Support{" "}
              </h5>
              <p style={{ paddingTop: "20px" }} className="mb-4">
                Excellence Care recognizes the unique needs of individuals with
                profound and multiple learning disabilities (PMLD), who face
                significant challenges in both communication and independence.{" "}
                <br />
                <br />
                We firmly believe that every individual with a learning
                disability, is entitled to the care, support, and opportunities
                necessary to lead a fulfilling and meaningful life.
                <br />
                <br />
                Here are a few of the many services we provide. <br />
                <br />• Ensure that individuals can understand even intricate
                information. <br />
                <br />
                • Foster the development of essential living skills, enabling
                individuals to fully participate in daily activities and
                experience a sense of accomplishment. <br />
                <br />
                • Promote continuity of relationships, ensuring that individuals
                have access to consistent and supportive connections. <br />
                <br />
                • Empower individuals to achieve greater independence, enabling
                them to manage their care and daily lives as much as possible
                and navigate their communities with confidence. <br />
                <br />
                • Personal care assistance with washing, oral hygiene, and other
                self-care tasks. <br />
                <br />• Communication support using Makaton, PECS, or other
                appropriate methods.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default LearningDis;
