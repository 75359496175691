import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import s3 from "./img/slider2 (1).jpeg";

import s2 from "./img/slider3.jpeg";
import s1 from "./img/slider1.jpeg";
import s4 from "./img/slider4.jpeg";

import Service from "./Service";
import Testimonial from "./Testimonial";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);

  return (
    <Fragment>
      <div
        className="container-fluid p-0"
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: "20px",
        }}
      >
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={s1} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    From Caring Hands
                    <br /> to Smiling Hearts{" "}
                  </h1>

                  <Link
                    to="/contact"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Request an Appointment
                  </Link>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <img className="w-100" src={s2} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    Ageless Excellence, <br /> Timeless Care{" "}
                  </h1>
                  <Link
                    to="/contact"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Request an Appointment
                  </Link>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <img className="w-100" src={s3} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    Providing Quality Care with
                    <br /> Dignity Since 2008
                    {/* <span>&#128512;</span> */}
                  </h1>
                  <Link
                    to="/contact"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Request an Appointment
                  </Link>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <img className="w-100" src={s4} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    Harmony of Care,Symphony
                    <br /> of Well-being {/* <span>&#128512;</span> */}
                  </h1>
                  <Link
                    to="/contact"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Request an Appointment
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* <Award /> */}
      <Service />
      <Testimonial />
    </Fragment>
  );
};

export default Home;
