import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import about from "../img/about.jpg";

const Gdpr = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);

  return (
    <Fragment>
      <div
        style={{ position: "relative", top: !isMobile ? "100px" : "0px" }}
        class="container-fluid bg-primary py-5 hero-header mb-5"
        className="maisbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              GDPR Policy{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              GDPR Policy{" "}
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        class="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-10">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Privacy Policy{" "}
                </h5>
              </div>

              <p className="mb-4">
                This privacy policy sets out how Excellence Care uses
                and protects any information that you give Our company when you use this website.
                <br />
                <br />
                Our company Excellence Care is committed to ensuring that your
                privacy is protected. Should we ask you to provide certain
                information by which you can be identified when using this
                website, then you can be assured that it will only be used in
                accordance with this privacy statement.
                <br />
                <br />
                Our company Excellence Care may change this policy from time to
                time by updating this page. You should check this page from time
                to time to ensure that you are happy with any changes. This
                policy is effective from May 2018.
              </p>

              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  What we collect
                </h5>
                <p className="mb-4">
                  We may collect the following information:
                  <br />
                  <br />
                  * name and job title
                  <br />
                  * contact information including email address
                  <br />
                  * demographic information such as postcode, preferences and
                  interests
                  <br />* other information relevant to customer surveys and/or
                  offers
                </p>
              </div>

              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  What we do with the information we gather
                </h5>
                <p className="mb-4">
                  We require this information to understand your needs and
                  provide you with a better service, and in particular for the
                  following reasons:
                  <br />
                  <br />
                  * Internal record keeping.
                  <br />
                  * We may use the information to improve our products and
                  services.
                  <br />
                  * We may periodically send promotional email about new
                  products, special offers or other information which we think
                  you may find interesting using the email address which you
                  have provided.
                  <br />* From time to time, we may also use your information to
                  contact you for market research purposes. We may contact you
                  by email, phone, fax or mail. We may use the information to
                  customise the website according to your interests.
                </p>
              </div>

              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Security{" "}
                </h5>
                <p className="mb-4">
                  We are committed to ensuring that your information is secure.
                  In order to prevent unauthorised access or disclosure we have
                  put in place suitable physical, electronic and managerial
                  procedures to safeguard and secure the information we collect
                  online.
                </p>
              </div>

              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  How we use cookies
                </h5>
                <p className="mb-4">
                  A cookie is a small file which asks permission to be placed on
                  your computer's hard drive. Once you agree, the file is added
                  and the cookie helps analyse web traffic or lets you know when
                  you visit a particular site. Cookies allow web applications to
                  respond to you as an individual. The web application can
                  tailor its operations to your needs, likes and dislikes by
                  gathering and remembering information about your preferences.
                  <br />
                  <br />
                  We use traffic log cookies to identify which pages are being
                  used. This helps us analyse data about web page traffic and
                  improve our website in order to tailor it to customer needs.
                  We only use this information for statistical analysis purposes
                  and then the data is removed from the system.
                  <br />
                  <br />
                  Overall, cookies help us provide you with a better website, by
                  enabling us to monitor which pages you find useful and which
                  you do not. A cookie in no way gives us access to your
                  computer or any information about you, other than the data you
                  choose to share with us.
                  <br />
                  <br />
                  You can choose to accept or decline cookies. Most web browsers
                  automatically accept cookies, but you can usually modify your
                  browser setting to decline cookies if you prefer. This may
                  prevent you from taking full advantage of the website. We also
                  have a new policy in effect due to the new regulations that
                  allows you to either accept or decline the cookie used on this
                  website.
                </p>
              </div>

              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Links to other websites
                </h5>
                <p className="mb-4">
                  Our website may contain links to other websites of interest.
                  However, once you have used these links to leave our site, you
                  should note that we do not have any control over that other
                  website. Therefore, we cannot be responsible for the
                  protection and privacy of any information which you provide
                  whilst visiting such sites and such sites are not governed by
                  this privacy statement. You should exercise caution and look
                  at the privacy statement applicable to the website in
                  question.
                </p>
              </div>

              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Controlling your personal information
                </h5>
                <p className="mb-4">
                  You may choose to restrict the collection or use of your
                  personal information in the following ways:
                  <br />
                  <br />
                  * whenever you are asked to fill in a form on the website,
                  look for the box that you can click to indicate that you do
                  not want the information to be used by anybody for direct
                  marketing purposes
                  <br />* if you have previously agreed to us using your
                  personal information for direct marketing purposes, you may
                  change your mind at any time by writing to or emailing us at
                  details on our website.
                  <br />
                  <br />
                  We will not sell, distribute or lease your personal
                  information to third parties unless we have your permission or
                  are required by law to do so. We may use your personal
                  information to send you promotional information about third
                  parties which we think you may find interesting if you tell us
                  that you wish this to happen.
                  <br />
                  <br />
                  You may request details of personal information which we hold
                  about you under the Data Protection Act 1998. A small fee will
                  be payable. If you would like a copy of the information held
                  on you please write to Our company.
                  <br />
                  <br />
                  If you believe that any information we are holding on you is
                  incorrect or incomplete, please write to or email us as soon
                  as possible, at the address on our website. We will promptly
                  correct any information found to be incorrect.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Gdpr;
