import "./App.css";
import { Fragment, useEffect, useState } from "react";
import Header from "./Header";
import Home from "./Home";
import Appointment from "./Appointment";
import About from "./about/About";
import Price from "./Price";
import Contact from "./Contact";
import Newsletter from "./Newsletter";
import Footer from "./Footer";
import SupportLiving from "./SupportLiving";
import CareServices from "./careservice/CareServices";
import Autism from "./careservice/autism.";
import LearningDis from "./careservice/learningdis";
import Team from "./Team";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import WOW from "wowjs";
import jQuery from "jquery";
import Head from "./Head";
import Mais from "./careservice/mais";
import Pmld from "./careservice/pmld";
import ExcVision from "./about/ExcVision";
import Welcoming from "./about/Welcoming";
import Awards from "./about/Awards";
import Covid from "./useful information/covid";
import HealthSafety from "./useful information/healthsafety";
import Laws from "./useful information/Laws";
import Preventing from "./useful information/Preventing";
import WhatisDom from "./careservice/whatisdom";
import Residential from "./careservice/Residential";
import Waverley from "./care home/Waverly";
import Career from "./career";
import Cranbrook from "./care home/Cranbrook";
import Eastern from "./care home/Eastern";
import Gdpr from "./policy/gdpr";
import BlogPage from "./blog/BlogPage";
import Grays from "./care home/Grays";
import GraWickfordys from "./care home/wickford";
import Wickford from "./care home/wickford";
import Clayburn from "./care home/clayburn";
import DaviesCourt from "./care home/daviesCourt";
import BenfleetHouse from "./care home/benfleetHouse";
import Priors from "./care home/Priors";
import SeaView from "./care home/seaView";
import DewLands from "./care home/dewlands";
import NorthColne from "./care home/northColne";
import ScrollToAnchor from "./scrollToNew";

function App() {
  useEffect(() => {
    (function ($) {
      "use strict";

      // Spinner
      var spinner = function () {
        setTimeout(function () {
          if ($("#spinner").length > 0) {
            $("#spinner").removeClass("show");
          }
        }, 1);
      };
      spinner();

      // Initiate the wowjs
      new WOW.WOW({
        live: false,
      }).init();

      // Sticky Navbar
      $(window).scroll(function () {
        if ($(this).scrollTop() > 40) {
          $(".navbar").addClass("sticky-top");
        } else {
          $(".navbar").removeClass("sticky-top");
        }
      });

      // Dropdown on mouse hover
      const $dropdown = $(".dropdown");
      const $dropdownToggle = $(".dropdown-toggle");
      const $dropdownMenu = $(".dropdown-menu");
      const showClass = "show";

      $(window).on("load resize", function () {
        if (this.matchMedia("(min-width: 992px)").matches) {
          $dropdown.hover(
            function () {
              const $this = $(this);
              $this.addClass(showClass);
              $this.find($dropdownToggle).attr("aria-expanded", "true");
              $this.find($dropdownMenu).addClass(showClass);
            },
            function () {
              const $this = $(this);
              $this.removeClass(showClass);
              $this.find($dropdownToggle).attr("aria-expanded", "false");
              $this.find($dropdownMenu).removeClass(showClass);
            }
          );
        } else {
          $dropdown.off("mouseenter mouseleave");
        }
      });

      // Back to top button
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $(".back-to-top").fadeIn("slow");
        } else {
          $(".back-to-top").fadeOut("slow");
        }
      });
      $(".back-to-top").click(function () {
        $("html, body").animate({ scrollTop: 0 }, 1500, "easeInOutExpo");
        return false;
      });
    })(jQuery);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Fragment>
          <Header />
          <ScrollToAnchor />
          <Route exact path="/" component={Home} />

          {/* <Team /> */}
          {/* <Appointment /> */}
          {/* <About /> */}
          {/* <SupportLiving /> */}
          {/* <Price /> */}
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/aboutus" component={About} />
          <Route exact path="/excellencecarevision" component={ExcVision} />
          <Route exact path="/welcomingdiversity" component={Welcoming} />
          <Route exact path="/awards" component={Awards} />
          <Route exact path="/covid19updates" component={Covid} />
          <Route exact path="/healthandsafety" component={HealthSafety} />
          <Route exact path="/laws" component={Laws} />
          <Route exact path="/preventingfalls" component={Preventing} />
          <Route exact path="/appointment" component={Appointment} />

          <Route exact path="/careservice" component={CareServices} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/autism" component={Autism} />
          <Route exact path="/learningdisability" component={LearningDis} />
          <Route exact path="/mais" component={Mais} />
          <Route exact path="/pmld" component={Pmld} />
          <Route exact path="/domiciliarycare" component={WhatisDom} />
          <Route exact path="/residentialcare" component={Residential} />
          <Route exact path="/waverleylodge" component={Waverley} />
          <Route exact path="/cranbrookmews" component={Cranbrook} />
          <Route exact path="/easternlodge" component={Eastern} />
          <Route exact path="/graysdeck" component={Grays} />
          <Route exact path="/wickford" component={Wickford} />
          <Route exact path="/clayburnlodge" component={Clayburn} />
          <Route exact path="/daviescourt" component={DaviesCourt} />
          <Route exact path="/benfleethouse" component={BenfleetHouse} />
          <Route exact path="/priors" component={Priors} />
          <Route exact path="/seaview" component={SeaView} />
          <Route exact path="/dewlands" component={DewLands} />
          <Route exact path="/northcolne" component={NorthColne} />

          <Route exact path="/career" component={Career} />
          <Route exact path="/gdpr" component={Gdpr} />
          <Route exact path="/blog" component={BlogPage} />

          <Newsletter />
          <Footer />
        </Fragment>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
