import { Fragment, useState, useEffect } from "react";
import about from "../img/resnew.jpg";

const HealthSafety = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="maisbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Health & Safety{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Health & Safety{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Health & Safety{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Staying informed, staying safe               </h1>
              </div>

              <p className="mb-4">
                the health and safety of our clients and staff is our top
                priority. We are committed to providing a safe and secure
                environment for everyone in our care, and we take proactive
                measures to prevent accidents, injuries, and illnesses.
                <br />
                <br />
                Our health and safety policies and procedures are designed to
                promote a culture of safety, accountability, and continuous
                improvement. We have a dedicated team of health and safety
                professionals who oversee our programs and ensure that we are in
                compliance with all applicable regulations and standards.
                <br />
                <br />
                Some of the key elements of our health and safety program
                include:
                <br />
                <br />
                • Risk assessments: We conduct regular assessments of our
                facilities, equipment, and operations to identify potential
                hazards and risks. We use this information to develop
                appropriate control measures and safety protocols.
                <br />
                <br />
                • Training and education: We provide comprehensive training and
                education to our staff on topics such as infection control,
                manual handling, fire safety, and emergency response. We also
                provide ongoing training to ensure that our staff stay
                up-to-date with the latest safety procedures and best practices.
                <br />
                <br />
                • Incident reporting and investigation: We have a formal
                incident reporting and investigation process that allows us to
                identify the root causes of incidents and take corrective action
                to prevent reoccurrence. We use this process to learn from our
                mistakes and continuously improve our safety performance.
                <br />
                <br />
                • Communication and consultation: We encourage open and honest
                communication with our clients, their families, and our staff on
                matters related to health and safety. We also consult with
                external experts and stakeholders to ensure that we are adopting
                the best possible practices.
                <br />
                <br />
                We believe that a strong health and safety culture is essential
                for providing high-quality care and support. We are committed to
                continuously improving our health and safety program and
                creating a safe and secure environment for everyone in our care.
                Regenerate response
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HealthSafety;
