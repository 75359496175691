import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import about from "../img/viside.png";
import "./about.scss";

const ExcVision = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="ecvm"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Excellence Care Vision & Mission{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Excellence Care Vision & Mission{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  OUR Vision & Mission{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Nurturing life's journey with compassionate care & support{" "}
                </h1>
              </div>

              <p className="mb-4">
                Excellence Care provides tailored support to adults with complex
                needs, empowering them to lead independent lives with dignity
                and control.
                <br />
                <br />
                • To provide tailored support to each service user's individual
                needs.
                <br />
                <br />
                • To provide non-discriminatory services.
                <br />
                <br />
                • To provide services that take into account service users'
                preferences, wishes, personal circumstances, and individual
                abilities.
                <br />
                <br />
                • To provide our service users with high-quality support within
                their own home environment. <br />
                <br />
                • To increase vulnerable adults awareness of their right to
                direct payments or individualized budgets.
                <br />
                <br />
                • To assist our clients in developing their own individualized
                support plans in full consultation with family, friends, and
                care professionals.
                <br />
                <br />
                • To provide services to clients in or outside their own homes
                at a time and in a way that is agreed upon by them.
                <br />
                <br />
                • To enable vulnerable adults to develop their confidence and
                maintain their dignity by retaining control of the care and
                support services they receive.
                <br />
                <br />
                • To provide opportunities to choose from the range of
                recreational, educational, voluntary, and employment
                opportunities available in the community.
                <br />
                <br />
                • To actively provide access to appropriate health and social
                care services, including specialist services for conditions not
                normally associated with our service users.
                <br />
                <br />• To provide experienced and committed staff with the
                appropriate expertise and training to provide a holistic service
                to adults with a range of complex needs.
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <h5
            style={{ marginTop: "20px" }}
            className="position-relative d-inline-block text-primary text-uppercase"
          >
            OUR MISSION{" "}
          </h5>
          <br />
          <p style={{ marginTop: "20px" }}>
            • By working to an individual plan of support that has been agreed
            with the service user.
            <br />
            <br />
            • By working with service users to promote and, where possible,
            increase their independence.
            <br />
            <br />
            • By treating all service users with dignity and respect. <br />
            <br />
            • By encouraging service users to be involved in the development of
            the service.
            <br />
            <br />
            • By ensuring that support is provided by trained and competent
            staff.
            <br />
            <br />
            • By supporting service users to access all community services
            available to them.
            <br />
            <br />• By upholding good governance and quality assurance
            practices.
          </p>
          <Link
            to="/contact"
            className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
            data-wow-delay="0.6s"
          >
            Make Appointment
          </Link>
          <a
            style={{
              backgroundColor: "#fff",
              color: "black",
              marginLeft: "15px",
              border: "1px solid green",
            }}
            className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
            href="https://wa.link/k4ywmn"
          >
            WhatsApp
            <i
              style={{ color: "green", marginLeft: "10px", fontSize: "20px" }}
              className="fab fa-whatsapp fw-normal"
            ></i>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default ExcVision;
