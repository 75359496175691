import { Fragment, useState, useEffect } from "react";
import about from "../img/ld.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import "./careservice.scss";

const Mais = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="maisbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Meeting the Accessible Information Standard{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Meeting the Accessible Information Standard{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}
        <div style={{}} className="container">
          <div className="row g-6">
            <div className="section-title mb-4">
              {" "}
              <h5 className="position-relative d-inline-block text-primary text-uppercase">
                Meeting the Accessible<br/> Information Standard{" "}
              </h5>
            </div>

            <div className="">
              <p style={{ paddingTop: "20px" }} className="mb-4">
                Excellence Care will endeavour to support people who have
                identified needs to be involved in the development and review of
                their own care planning and support. We look at ways of ensuring
                that everyone is supported to understand in a way that suits
                their communication needs. This may be in the format of having a
                pictorial, audio, or braille support plan as an example.
                <br />
                <br />
                Our Accessible Communication policy will ensure that systems and
                processes are in place for Excellence Care services to identify,
                record, flag, share and meet the information and communication
                needs for the people we support, their families and/or carers
                where those needs relate to a disability, impairment, or sensory
                loss. We will ensure that people are able to access our
                complaints policy and safeguarding policy in an appropriate
                format that reflects their level of understanding. Excellence
                Care is committed to implementing and following the five
                distinct steps of meeting the Accessible Information Standard
                (AIS) which are: <br />
                Identification of needs: a consistent approach to the
                identification of information and communication needs for the
                people we support, where they relate to a disability,
                impairment, or sensory needs. <br />
                Recording of needs: a consistent and routine recording of
                information and communication needs as part of our records or
                systems. The use of different categories of information and
                communication as defined by the AIS when recording information
                in paper or electronic records. <br />
                Flagging of needs: establishment and use of electronic flags or
                alerts, or paper- based equivalents, to ensure re communication
                and /or information needs are highly visible to prompt staff to
                take appropriate action and / or trigger auto-generation of
                information in an accessible format / other actions such needs
                can be met. <br />
                Sharing of needs: inclusion of recorded data about individuals’
                information and / or communication support needs as part of
                existing data-sharing processes, and as a routine referral,
                discharge, and handover processes. <br />
                Meeting of needs: taking steps to ensure that the individual
                receives information in an accessible format and any
                communication support which the staff have received training in
                Understanding Autism, Mental Health, Learning Disabilities
                Awareness, Challenging Behaviour, Equality & Diversity as well
                as The Mental Capacity Act and DoLS. The organisation is now in
                the stages of implementing <br />
                <br />
                the ‘Oliver McGowan Mandatory Training in Learning Disabilities
                and Autism and this will allow our staff to have better
                knowledge and support to enable them to deliver high-quality
                person-centred care and support.
                <br />
                <br />
                Support staff will act on the information provided and will:
                Take appropriate action to enable people to communicate
                effectively, including staff modifying their behaviour and/or
                with the use of communication aids. Ensure that individuals
                receive information in a format they can access and understand
                e.g. pictures, large print, easy read. Match peoples’
                communication methods with appropriate staff that will support
                them to communicate and be understood effectively with the
                person. Care and support staff will support the person to
                understand their rights under the AIS when accessing health
                appointments. The states that ‘where needed, appropriate,
                qualified, professional communication support MUST be arranged
                or provided to enable individuals to effectively access /
                receive health or adult social care facilitate effective /
                accurate dialogue, and to enable participation in decisions
                about their health, care or treatment.’ Contact people using
                their preferred method of communication and provide ways for
                people to contact the service. Inform people of the potential
                confidentiality risk when sending/receiving information via
                email or text – refer to Data Protection Policy. Signpost
                accessible information available on our website and other
                sources including BSL video. Allocate time to people supported
                to meet their communication needs, funding permitting. Where
                funding is available and sufficient for appropriate
                communication aides and technology, service managers will
                support the person to address this with the relevant funding
                body. Staff will receive training as part of their induction
                process about sensory loss, AIS awareness and how to support
                people with communication needs, as well as BSL / Makaton
                training where appropriate. Compliance with the be monitored
                through the quality monitoring process and we will review our
                learning taking remedial action where needed.
              </p>
              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Mais;
