import { Fragment, useState, useEffect } from "react";
// import about from "../img/wav.jpg";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import wav1 from "../img/clay1.jpg";
import wav2 from "../img/wick1.jpg";
import wav3 from "../img/wick3.jpg";

import "./care.scss";

const Waverley = () => {
  const options = {
    loop: true,
    margin: 45,
    items: 2,
    autoplay: true,
    nav: true,
  };
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="chb"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Waverley Lodge{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Waverley Lodge{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container">
            {/* cont1 */}
            <div className="row g-5">
              <div className="col-lg-5">
                <div className="section-title mb-4">
                  <h5 className="position-relative d-inline-block text-primary text-uppercase">
                    Waverley Lodge{" "}
                  </h5>
                </div>
                <p className="mb-4">
                  Waverly Lodge is nestled in a quiet residential neighborhood
                  at 26 Waverly Road, E173LQ,just a few blocks away from the
                  town center <br />
                  <br />
                  Convenience surrounds Waverly Lodge, with Wood Street Market,
                  a haven for fresh produce and household essentials, just a
                  4-minute stroll away. For spiritual solace, Masjid Abu Bakr
                  mosque and Church are within a short 9-minute and 3-minute
                  walk, respectively. <br />
                  <br />
                  Local shops, cafes, restaurants, and a community center
                  teeming with activities enrich the lives of Waverly Lodge
                  residents. And for medical needs, Whipps Cross Hospital is a
                  mere 1.9 miles away, ensuring timely care. <br />
                  <br />
                  Outdoor enthusiasts will find solace in the nearby park, a
                  tranquil escape from the hustle and bustle of city life.{" "}
                  <br />
                  <br />
                  Waverly Lodge proudly embraces its role in the E173LQ
                  community, providing a sanctuary of comfort, care, and
                  convenience for adults seeking high-quality living. Visit us
                  and discover why Waverly Lodge is the ideal choice for your
                  loved ones.
                </p>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  01268 988500{" "}
                </h1>
              </div>

              <div className="col-lg-7">
                <OwlCarousel
                  className="owl-theme"
                  {...options}
                  style={{
                    navText: [
                      '<i class="bi bi-arrow-left"></i>',
                      '<i class="bi bi-arrow-right"></i>',
                    ],
                    responsive: {
                      0: {
                        items: 1,
                      },
                      768: {
                        items: 2,
                      },
                    },
                  }}
                >
                  <div className="price-item pb-4">
                    <a href="#SLive">
                      <div className="position-relative">
                        <img
                          className="img-fluid rounded-top"
                          src={wav1}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>

                  <div className="price-item pb-4">
                    <div className="position-relative">
                      <img
                        className="img-fluid rounded-top"
                        src={wav2}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="price-item pb-4">
                    <div className="position-relative">
                      <img
                        className="img-fluid rounded-top"
                        src={wav3}
                        alt=""
                      />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>

            {/* cont2 */}
            <div style={{ marginTop: "20px" }} className="row g-5">
              <div className="col-lg-5">
                <div className="mb-4">
                  <h5 className="text-primary text-uppercase">
                    Nominated Individual/ Care
                    <br /> & Compliance Manager{" "}
                  </h5>
                </div>
                <img
                  style={{ height: "300px" }}
                  src={require("../img/team-11.jpeg")}
                />
                <h2
                  style={{ marginTop: "20px" }}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  {/* Mr Kaveerajsing Baungally{" "} */}
                </h2>
              </div>
              <div className="col-lg-5">
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  Michelle{" "}
                </h1>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  01268 988500{" "}
                </h5>
                <p>
                  I serve as the Nominated Individual/Care & Compliance
                  Manager at Excellence Care. My name is Michelle, and I am
                  available for contact via phone at <b>01268 988500</b> or
                  through email at <b>info@excellence-care.co.uk</b> Feel free
                  to reach out to me for any inquiries or assistance related to
                  our care services.
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  Make Appointment
                </Link>
                <a
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    marginLeft: "15px",
                    border: "1px solid green",
                  }}
                  className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                  href="https://api.whatsapp.com/send?phone=447402951877&text=Hello%20Excellence%20Care!"
                >
                  WhatsApp
                  <i
                    style={{
                      color: "green",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                    className="fab fa-whatsapp fw-normal"
                  ></i>
                </a>
              </div>
            </div>

            <div style={{ marginTop: "20px" }} className="row g-5">
              <div className="col-lg-5">
                <div className="mb-4">
                  <h5 className="text-primary text-uppercase">
                    Registered Manager{" "}
                  </h5>
                </div>
                <img
                  style={{ height: "300px" }}
                  src={require("../img/rishinew.png")}
                />
                <h2
                  style={{ marginTop: "20px" }}
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  {/* Mr Kaveerajsing Baungally{" "} */}
                </h2>
              </div>
              <div className="col-lg-5">
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Call for Appointment
                </h5>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  Mr Kaveerajsing Baungally
                </h1>
                <h5
                  className="text-uppercase text-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  01268 988500{" "}
                </h5>
                <p>
                  I am the Registered Manager and my name is Mr Kaveerajsing
                  Baungally but I like to be called ‘Rishi’ You can contact me
                  by phone on <b> 01268 988500 </b> Or Email:
                  <b> info@excellence-care.co.uk</b>
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  Make Appointment
                </Link>
                <a
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    marginLeft: "15px",
                    border: "1px solid green",
                  }}
                  className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                  href="https://api.whatsapp.com/send?phone=447402951877&text=Hello%20Excellence%20Care!"
                >
                  WhatsApp
                  <i
                    style={{
                      color: "green",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                    className="fab fa-whatsapp fw-normal"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Waverley;
{
  /* <div className="container">
<div className="row g-5">
  <div className="col-lg-7">
    <div className="section-title mb-4">
      <h5 className="position-relative d-inline-block text-primary text-uppercase">
        Waverley Lodge{" "}
      </h5>
      <h1 className="display-5 mb-0">
        {" "}
        Making every day feel like home{" "}
      </h1>
    </div>

    <p className="mb-4">
      Our priority is that you are safe and will work with you to
      ensure this is the case. We celebrate individuality and
      diversity and ask that everyone shows respect towards one
      another even when we disagree. We will support you with
      achieving the next steps in your life and look forward to lots
      of laughs and fun along the way 😊”.
      <br />
      <br />
      <b>Registered Manager</b>
      <br />
      <br />
      <img
        style={{ height: "400px" }}
        src={require("../img/rishi.jpeg")}
      />
      <br />
      <br />
      I am the Registered Manager and my name is Mr Kaveerajsing
      Baungally but I like to be called ‘Rishi’
      <br />
      <br />
      You can contact me by phone on 07402 951877 <br />
      <br />
      Email: rishi.baungally@excellence-care.co.uk
    </p>
    <a
      href="appointment.html"
      className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
      data-wow-delay="0.6s"
    >
      Make Appointment
    </a>
    <a
      style={{
        backgroundColor: "#fff",
        color: "black",
        marginLeft: "15px",
      }}
      className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
      href="#"
    >
      WhatsApp
      <i
        style={{
          color: "green",
          marginLeft: "10px",
          fontSize: "20px",
        }}
        className="fab fa-whatsapp fw-normal"
      ></i>
    </a>
  </div>
  <div className="col-lg-7">
    <OwlCarousel
      className="owl-theme"
      {...options}
      style={{
        navText: [
          '<i class="bi bi-arrow-left"></i>',
          '<i class="bi bi-arrow-right"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
        },
      }}
    >
      <div className="price-item pb-4">
        <a href="#SLive">
          <div className="position-relative">
            <img className="img-fluid rounded-top" src={s1} alt="" />
            <div
              className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
              style={{ zIndex: 2 }}
            >
              <h2
                style={{ width: "max-content" }}
                className="btn btn-primary  py-2 px-4  position-absolute top-100 start-50 translate-middle"
              >
                Supported Living
              </h2>
            </div>
          </div>
        </a>
      </div>
      <div className="price-item pb-4">
        <div className="position-relative">
          <img className="img-fluid rounded-top" src={s3} alt="" />
          <div
            className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
            style={{ zIndex: 2 }}
          >
            <h2 className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle">
              Domiciliary
            </h2>
          </div>
        </div>
      </div>

      <div className="price-item pb-4">
        <div className="position-relative">
          <img className="img-fluid rounded-top" src={re} alt="" />
          <div
            className="d-flex align-items-center justify-content-center bg-light rounded pt-2 px-3 position-absolute top-100 start-50 translate-middle"
            style={{ zIndex: 2 }}
          >
            <h2 className="btn btn-primary py-2 px-4 position-absolute top-100 start-50 translate-middle">
              Residential
            </h2>
          </div>
        </div>
      </div>
    </OwlCarousel>
  </div>
</div>
</div> */
}
