import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logouk from "./img/logouk.png";
import image from "./img/image.png";
import jQuery, { trim } from "jquery";
import { Row, Col, NavDropdown, Container, Navbar, Nav } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "react-bootstrap-submenu/dist/index.css";
import Home from "./Home";
import Head from "./Head";
import useWindowSize from "./useWindowSize";
import "./css/header.css";

const Header = () => {
  const [open, setOpen] = useState(false);

  const [dropunder, setDropunder] = useState("");
  useEffect(() => {
    if (window.performance) {
      handleResize();
      handleResize1();
      handleResize2();
      if (performance.navigation.type == 1) {
        //  window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);
          window.addEventListener("resize", handleResize1);
          window.addEventListener("resize", handleResize2);
          //                    window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  //  console.log("props value",props.mobileView)
  const [stickyClass, setStickyClass] = useState("");
  const [isHide, setIsHide] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [isMobile1, setIsMobile1] = useState(false);
  const [isMobile2, setIsMobile2] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 450) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleResize1 = () => {
    if (window.innerWidth < 990) {
      setIsMobile1(true);
    } else {
      setIsMobile1(false);
    }
  };

  const handleResize2 = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-top") : setStickyClass("");
    }
  };

  const hideOnCLick = () => {
    setIsHide(true);
    setTimeout(() => {
      setIsHide(false);
    }, 700);
  };

  useEffect(() => {
    (function ($) {
      "use strict";
    })(jQuery);
  }, []);

  const reload = () => {
    window.location.reload();
  };

  const hideCss = {
    dropdownMenu: {
      display: "none",
    },
  };

  const size = useWindowSize();
  return (
    <Fragment>
      {isMobile && <Head />}
      {!isMobile && (
        <>
          {" "}
          <nav
            className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0"
            style={{
              position: "fixed",
              zIndex: 30,
              width: "100%",
              top: !isMobile1 ? 0 : 0,
            }}
          >
            <Link to="/" className="navbar-brand p-0">
              <img style={{ height: "100px" }} src={logouk} alt="logo" />
            </Link>
            <div class="collapse navbar-collapse" id="navbarCollapse"></div>

            <div className="d-flex">
              <a
                style={{ color: "#843A77" }}
                className="btn btn-lg btn-lg-circle rounded "
                href="https://www.facebook.com/ExcellenceCareUK/"
              >
                <i className="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/excellence-care/?viewAsMember=true"
                style={{ color: "#843A77" }}
                className="btn btn-lg btn-lg-circle rounded "
              >
                <i className="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a
                style={{ color: "#843A77" }}
                className="btn btn-lg btn-lg-circle rounded"
                href="https://www.instagram.com/excellencecare.uk/"
              >
                <i className="fab fa-instagram fw-normal"></i>
              </a>
            </div>

            {/* <div
              style={{
                flexBasis: "30%",
                display: "flex",
                position: "relative",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  padding: "5px 0",
                  flexBasis: "50%",
                  textAlign: "center",
                }}
              >
                <Link
                  onClick={() => {
                    setOpen(false);
                  }}
                  to="/career"
                  style={{ backgroundColor: "#F28C28" }}
                  className="btn btn-primary"
                >
                  Empower your career
                </Link>
              </div>
              <div
                style={{
                  padding: "5px 0",
                  flexBasis: "50%",
                  textAlign: "center",
                }}
              >
                <a
                  style={{ backgroundColor: "#F28C28" }}
                  className="btn btn-primary"
                >
                  Discover Excellence
                </a>
              </div>
            </div> */}
            <div
              style={{ margin: "auto", display: "block", marginLeft: "10px" }}
            >
              <h5
                style={{ marginLeft:"10px"  }}
                className=" d-inline-block text-primary"
              >
                Looking for Care?{" "}
              </h5>

              <br />
              <a
                href="tel:012689 88500"
                style={{ backgroundColor: "#F28C28" }}
                className="btn btn-primary"
              >
                Call: 012689 88500
              </a>
            </div>
          </nav>
          <nav
            className="navbar navbar navbar-expand-lg navbar-dark bg-primary"
            style={{
              position: "sticky",
              zIndex: 30,
              width: "100%",
              top: !isMobile ? 100 : 115,
            }}
          >
            <div
              style={{ marginLeft: "4%" }}
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="slideHover nav-item">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>

                <li className="slideHover nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About us
                  </a>
                  <div
                    // style={{ display: "none" }}
                    className={`dropdown-menu ${isHide ? "hideOnClick" : ""}`}
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="section-title mb-4">
                            <h5 className="position-relative d-inline-block text-primary text-uppercase">
                              About Us
                            </h5>
                          </div>

                          <p className="navbar-sub">
                            Excellence care offers supported living, domiciliary
                            care, and residential care services to help
                            individuals live as independently as possible. This
                            care is tailored to each individual and their
                            specific needs, providing physical and emotional
                            support as well as practical assistance.
                          </p>
                          <Link
                            style={{ textDecoration: "underline" }}
                            to="/aboutus"
                            onClick={hideOnCLick}
                          >
                            Go to About us page
                          </Link>
                        </div>
                        <div className="col-md-4">
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <Link
                                value="Reload Page"
                                to="/excellencecarevision"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                className="hoverUnderline nav-link "
                                onClick={hideOnCLick}
                              >
                                Our Vision & Mission{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/aboutus"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                className="hoverUnderline nav-link "
                                href="#"
                                onClick={hideOnCLick}
                              >
                                Our Journey{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/awards"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                className="hoverUnderline nav-link "
                                href="#"
                                onClick={hideOnCLick}
                              >
                                Our Awards
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link
                                to="/team"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                className="hoverUnderline nav-link "
                                onClick={hideOnCLick}
                              >
                                Our Team
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/welcomingdiversity"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                className="hoverUnderline nav-link "
                                href="#"
                                onClick={hideOnCLick}
                              >
                                We Encourage Diversity{"   "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/#testimonial"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                className="hoverUnderline nav-link "
                                onClick={hideOnCLick}
                              >
                                Testimonial
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-4">
                          <a target="_blank" href="">
                            <img
                              style={{ height: "300px" }}
                              src={image}
                              alt="Web Design Guides"
                              className="img-fluid"
                            />
                          </a>
                          <p className="text-white">Get Free Guides</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="slideHover nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Care Services
                  </a>
                  <div
                    className={`dropdown-menu ${isHide ? "hideOnClick" : ""}`}
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="section-title mb-4">
                            <h5 className="position-relative d-inline-block text-primary text-uppercase">
                              Care Services
                            </h5>
                          </div>

                          <p className="navbar-sub">
                            Our services are tailored to each individual’s
                            needs, so you can be sure that you are receiving the
                            best possible care. Our team takes the time to get
                            to know each individual, so that we can provide
                            personalized, tailored services that offer the
                            highest quality of care.
                          </p>
                          <Link
                            style={{ textDecoration: "underline" }}
                            to="/careservice"
                            onClick={hideOnCLick}
                          >
                            Go to Care Services page
                          </Link>
                        </div>

                        <div className="col-md-3">
                          <ul className="nav flex-column">
                            <li className="nav-item ">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownPre"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                onClick={() => {
                                  setDropunder("pf");
                                }}
                              >
                                Supported Living
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownPre"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                onClick={() => {
                                  setDropunder("sc");
                                }}
                              >
                                Home Care
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownPre"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{
                                  color: "#6b6a75",
                                  textAlign: "left",
                                }}
                                onClick={() => {
                                  setDropunder("res");
                                }}
                              >
                                Residential
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-4">
                          {dropunder === "pf" && (
                            <ul className=" flex-column">
                              <Link
                                // onClick={nowHide}
                                to="/autism"
                                onClick={hideOnCLick}
                              >
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Autism
                                </li>
                              </Link>
                              <Link
                                to="learningdisability"
                                onClick={hideOnCLick}
                              >
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Learning Disability
                                </li>
                              </Link>

                              <Link to="/pmld" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Profound and Multiple Learning Disability
                                  (PMLD){" "}
                                </li>
                              </Link>
                            </ul>
                          )}
                          {dropunder === "sc" && (
                            <ul className="flex-column">
                              <Link to="/domiciliarycare" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  What is Home Care / Domiciliary Care?
                                </li>
                              </Link>
                            </ul>
                          )}
                          {dropunder === "res" && (
                            <ul className="flex-column">
                              <Link to="/residentialcare" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  What is Residential Care?
                                </li>
                              </Link>
                            </ul>
                          )}
                          {!dropunder && (
                            <a target="_blank" href="">
                              <img
                                style={{ height: "300px" }}
                                src={image}
                                alt="Web Design Guides"
                                className="img-fluid"
                              />
                            </a>
                          )}
                          <p className="text-white">Get Free Guides</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="slideHover nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Useful Information
                  </a>
                  <div
                    className={`dropdown-menu ${isHide ? "hideOnClick" : ""}`}
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="section-title mb-4">
                            <h5 className="position-relative d-inline-block text-primary text-uppercase">
                              Useful Information
                            </h5>
                          </div>

                          <p className="navbar-sub">What you need to know.</p>
                        </div>

                        <div className="col-md-4">
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <Link
                                to="/covid19updates"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                className="hoverUnderline nav-link active"
                                href="#"
                                onClick={() => {
                                  setDropunder("");
                                  hideOnCLick();
                                }}
                              >
                                Covid 19 updates{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="blog"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                className="hoverUnderline nav-link "
                                href="#"
                                onClick={() => {
                                  setDropunder("");
                                  hideOnCLick();
                                }}
                              >
                                Blog{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="healthandsafety"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                className="hoverUnderline nav-link "
                                href="#"
                                onClick={() => {
                                  setDropunder("");
                                  hideOnCLick();
                                }}
                              >
                                Health & Safety
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/laws"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                className="hoverUnderline nav-link "
                                href="#"
                                onClick={() => {
                                  setDropunder("");
                                  hideOnCLick();
                                }}
                              >
                                Laws{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/preventingfalls"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                className="hoverUnderline nav-link "
                                href="#"
                                onClick={() => {
                                  setDropunder("");
                                  hideOnCLick();
                                }}
                              >
                                Preventing falls{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="mais"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                className="hoverUnderline nav-link "
                                onClick={hideOnCLick}
                              >
                                {" "}
                                Meeting the Accessible Information Standard
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-4">
                          {dropunder === "pf" && (
                            <ul className="flex-column"></ul>
                          )}
                          {/* {dropunder === "sc" && (
                            <ul className="nav flex-column">
                              <li className=" nav-item">support one</li>
                              <li className=" nav-item">support Two</li>
                            </ul>
                          )} */}
                          {!dropunder && (
                            <a target="_blank" href="">
                              <img
                                style={{ height: "300px" }}
                                src={image}
                                alt="Web Design Guides"
                                className="img-fluid"
                              />
                            </a>
                          )}
                          <p className="text-white">Get Free Guides</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="slideHover nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Our Sites
                  </a>
                  <div
                    className={`dropdown-menu ${isHide ? "hideOnClick" : ""}`}
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="section-title mb-4">
                            <h5 className="position-relative d-inline-block text-primary text-uppercase">
                              Our Sites
                            </h5>
                          </div>

                          <p className="navbar-sub">
                            We invite you to embark on a journey of discovery,
                            immersing yourself in our wealth of information and
                            uncovering the transformative power of home care
                          </p>
                        </div>

                        <div className="col-md-3">
                          <ul className="nav flex-column">
                            <li className="nav-item ">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownPre"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                onClick={() => {
                                  setDropunder("london");
                                }}
                              >
                                London{" "}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownPre"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                onClick={() => {
                                  setDropunder("sc");
                                }}
                              >
                                Essex
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownPre"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ color: "#6b6a75", textAlign: "left" }}
                                onClick={() => {
                                  setDropunder("res");
                                }}
                              >
                                staffordshire
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-4">
                          {dropunder === "london" && (
                            <ul className="flex-column">
                              <Link to="/waverleylodge" onClick={hideOnCLick}>
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Waverly Lodge
                                </li>
                              </Link>
                              <Link to="/cranbrookmews" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Cranbrook Mews{" "}
                                </li>
                              </Link>
                              <Link to="/easternlodge" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Eastern Lodge{" "}
                                </li>
                              </Link>
                            </ul>
                          )}
                          {dropunder === "sc" && (
                            <ul className="flex-column">
                              <Link to="/graysdeck" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Grays Decking
                                </li>
                              </Link>
                              <Link to="/wickford" onClick={hideOnCLick}>
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Wickford{" "}
                                </li>
                              </Link>
                              <Link to="/clayburnlodge" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Clayburn lodge{" "}
                                </li>
                              </Link>
                              <Link to="/daviescourt" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  St Davies Court{" "}
                                </li>
                              </Link>
                              <Link to="/benfleethouse" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Benfleet house
                                </li>
                              </Link>
                              <Link to="/priors" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Priors{" "}
                                </li>
                              </Link>
                              <Link to="/seaview" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Sea View{" "}
                                </li>
                              </Link>
                              <Link to="/dewlands" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Dewlands{" "}
                                </li>
                              </Link>
                              <Link to="/northcolne" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  North Colne
                                </li>
                              </Link>
                            </ul>
                          )}
                          {dropunder === "res" && (
                            <ul className="flex-column">
                              <Link to="/domiciliarycare" onClick={hideOnCLick}>
                                {" "}
                                <li
                                  style={{ color: "#6b6a75" }}
                                  className="hoverUnderline nav-item"
                                >
                                  Staffordshire{" "}
                                </li>
                              </Link>
                            </ul>
                          )}
                          {!dropunder && (
                            <a target="_blank" href="">
                              <img
                                style={{ height: "300px" }}
                                src={image}
                                alt="Web Design Guides"
                                className="img-fluid"
                              />
                            </a>
                          )}
                          <p className="text-white">Get Free Guides</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="slideHover nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </li>

                {/* <button
                  style={{ color: "#fff" }}
                  type="button"
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#searchModal"
                >
                  <i class="fa fa-search"></i>
                </button> */}
              </ul>
            </div>
          </nav>{" "}
        </>
      )}
    </Fragment>
  );
};

export default Header;
