import React from 'react';
import ReactDOM from 'react-dom/client';
import "./scss/bootstrap/scss/bootstrap.scss"
import "./css/bootstrap.min.css"
import "./css/newstyle.css"
import "./css/style.css"

//import "react-bootstrap-submenu/dist/index.css";
//import "./scss/bootstrap/scss/_navbar.scss"
//import "./scss/bootstrap/scss/_variables.scss"
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
reportWebVitals();
