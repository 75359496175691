import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import about from "../img/autism.gif";
import "./careservice.scss";
const CareServices = () => {
  const [show, setShow] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [showDom, setShowDom] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const showNow = () => {
    setShow(!show);
  };

  const showResi = () => {
    setShowRes(!showRes);
  };

  const showDomi = () => {
    setShowDom(!showDom);
  };
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="careserviceBackground"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              CareServices{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              CareServices
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  CareServices{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Compassionate Care, Every Step of the Way{" "}
                </h1>
              </div>
              {/* <h4 className="text-body fst-italic mb-4">
                Diam dolor diam ipsum sit. Clita erat ipsum et lorem stet no
                lorem sit clita duo justo magna dolore
              </h4> */}
              <p className="mb-4">
                Our services are tailored to each individual’s needs, so you can
                be sure that you are receiving the best possible care. Our team
                takes the time to get to know each individual, so that we can
                provide personalized, tailored services that offer the highest
                quality of care.
                <br />
                <br />
                We believe that everyone deserves to live their best life,
                regardless of age, ability, or health conditions. Our mission is
                to provide high-quality care services that empower individuals
                to live their lives to the fullest and maintain their
                independence, dignity, and comfort.
              </p>
              <div style={{ cursor: "pointer" }} className="row g-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                  <h5 onClick={showNow} className="mb-3">
                    {show ? (
                      <i className="fa fa-chevron-down text-primary me-3"></i>
                    ) : (
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                    )}
                    {/* <i className="fa fa-chevron-right text-primary me-3"></i> */}
                    Supported Living
                  </h5>
                  {show ? (
                    <div style={{ marginLeft: "40px", marginBottom: "20px" }}>
                      <li>
                        <Link to="/autism">Autism</Link>
                      </li>
                      <li>
                        <Link to="/learningdisability">
                          Learning Disability
                        </Link>
                      </li>
                      <li>
                        <Link to="/pmld">
                          Profound and Multiple Learning Disability (PMLD){" "}
                        </Link>
                      </li>
                    </div>
                  ) : (
                    ""
                  )}
                  <h5 onClick={showResi} className="mb-3">
                    {showRes ? (
                      <i className="fa fa-chevron-down text-primary me-3"></i>
                    ) : (
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                    )}{" "}
                    Residential
                  </h5>
                  {showRes ? (
                    <div style={{ marginLeft: "40px", marginBottom: "20px" }}>
                      <li>
                        <Link to="/residentialcare">
                          What is Residential Care?
                        </Link>
                      </li>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.6s">
                  <h5 onClick={showDomi} className="mb-3">
                    {showDom ? (
                      <i className="fa fa-chevron-down text-primary me-3"></i>
                    ) : (
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                    )}{" "}
                    Home Care
                  </h5>
                  {showDom ? (
                    <div style={{ marginLeft: "40px", marginBottom: "20px" }}>
                      <li>
                        <Link to="/domiciliarycare">
                          What is Home Care / Domiciliary Care?
                        </Link>
                      </li>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* container2 */}

        {/* <div style={{ marginTop: "80px" }} className="container">
          <div className="row g-6">
            <div className="">
              <div className="section-title mb-12">
                <h4
                  style={{ textAlign: "center" }}
                  className="text-primary text-uppercase"
                >
                  Supported Living{" "}
                </h4>
                <h1
                  style={{ paddingTop: "10px", textAlign: "center" }}
                  className="display-5 mb-0"
                >
                  {" "}
                  Nurturing Growth and Well-being{" "}
                </h1>
              </div>

              <p style={{ paddingTop: "20px" }} className="mb-4">
                Supported living is a type of living arrangement for people with
                learning disabilities and autism. It is designed to provide a
                safe, supportive and inclusive environment for these individuals
                to live in. Supported living environments can provide access to
                the necessary resources and services to help individuals achieve
                their goals.
              </p>
              <div style={{ cursor: "pointer" }} className="row g-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                  <h5 className="mb-3">
                    <i className="fa fa-chevron-right text-primary me-3"></i>
                    <Link to="/autism"> Autism</Link>
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-chevron-right text-primary me-3"></i>
                    <Link to="/learningdisability"> Learning Disability </Link>
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.6s">
                  <h5 className="mb-3">
                    <Link to="/mais">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Meeting the Accessible Information Standard
                    </Link>
                  </h5>
                  <h5 className="mb-3">
                    <Link to="/pmld">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Profound and Multiple Learning Disability (PMLD)
                    </Link>
                  </h5>
                </div>
              </div>

              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
          </div>
        </div> */}

        {/* container3 */}
        {/* <div style={{ marginTop: "60px" }} className="container">
          <div className="row g-6">
            <div className="">
              <div className="section-title mb-12">
                <h4
                  style={{ textAlign: "center" }}
                  className="text-primary text-uppercase"
                >
                  Domiciliary{" "}
                </h4>
                <h1
                  style={{ paddingTop: "10px", textAlign: "center" }}
                  className="display-5 mb-0"
                >
                  {" "}
                  Dedicated to Your Independence and Well-being{" "}
                </h1>
              </div>

              <p style={{ paddingTop: "20px" }} className="mb-4">
                We understand the importance of staying in the comfort of your
                own home, even as your care needs change. Our mission is to
                provide high-quality, compassionate domiciliary care services
                that enable individuals to maintain their independence, dignity,
                and quality of life.
              </p>
              <div style={{ cursor: "pointer" }} className="row g-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                  <h5 className="mb-3">
                    <i className="fa fa-chevron-right text-primary me-3"></i>
                    <Link to="/domiciliarycare">What is Domiciliary Care?</Link>
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-chevron-right text-primary me-3"></i>
                    <Link to="/domiciliarycare">
                      {" "}
                      Types of Domiciliary Care
                    </Link>
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-chevron-right text-primary me-3"></i>
                    <Link to="/domiciliarycare">
                      {" "}
                      Who can benefit from Domiciliary Care
                    </Link>{" "}
                  </h5>
                </div>
              </div>

              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
          </div>
        </div> */}

        {/* container4 */}
        {/* <div style={{ marginTop: "60px" }} className="container">
          <div className="row g-6">
            <div className="">
              <div className="section-title mb-12">
                <h4
                  style={{ textAlign: "center" }}
                  className="text-primary text-uppercase"
                >
                  Residential{" "}
                </h4>
                <h1
                  style={{ paddingTop: "10px", textAlign: "center" }}
                  className="display-5 mb-0"
                >
                  {" "}
                  Uncompromising Quality of Life{" "}
                </h1>
              </div>

              <p style={{ paddingTop: "20px" }} className="mb-4">
                We believe that every individual deserves a comfortable,
                supportive, and dignified living environment, especially as they
                age or face challenges with their health. Our mission is to
                provide exceptional residential care services in a warm and
                welcoming environment, where residents can live their best life.
              </p>
              <div style={{ cursor: "pointer" }} className="row g-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                  <h5 className="mb-3">
                    <Link to="/residentialcare">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Residential Care
                    </Link>
                  </h5>
                  <h5 className="mb-3">
                    <Link to="/residentialcare">
                      <i className="fa fa-chevron-right text-primary me-3"></i>
                      Benefits of Residential Care
                    </Link>
                  </h5>
                </div>
              </div>

              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="https://wa.link/k4ywmn"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default CareServices;
