import { Fragment, useState, useEffect } from "react";
import about from "../img/mha.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import "./careservice.scss";

const Autism = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="autismbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              Autism{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              Autism
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Autism{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Creating a World Where Everyone with Autism Can Thrive{" "}
                </h1>
              </div>

              <p className="mb-4">
                Excellence Care has been supporting people with autism since the
                organisation started in 2008. We embrace the CQC's 'Right
                Support, Right Care, Right Culture' guidance when we work with
                individuals who have an autism spectrum diagnosis.
                <br />
                <br />
                <b>• Right Support</b> – We empower individuals to make informed
                decisions about their care, fostering their independence by
                supporting meaningful engagement in daily activities and
                encouraging active participation in all aspects of their lives.
                <br />
                <br />
                <b> • Right Care</b> – Excellence Care takes pride in the caring
                approach and attitude of our staff who interact daily with the
                people we support. Our staff always take a person- centered
                approach which promotes everyone's dignity, privacy, and human
                rights.
                <br />
                <br />
                <b>• Right Culture </b>– We are committed to upholding the
                organization's values of embracing its ethos and treating all
                individuals with respect and equality. Our leadership team
                fosters an open and transparent environment, ensuring that all
                individuals feel safe, confident, included, and empowered to
                pursue their aspirations.
              </p>
            </div>

            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
        <h2
          style={{
            marginTop: "60px",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            color: "#F28C28",
            textAlign: "center",
          }}
        >
          "Although people with autism may face challenges with social
          communication and interaction, many develop deep and meaningful
          relationships with those around them."
        </h2>
        <div style={{ marginTop: "50px" }} className="container">
          <div className="row g-6">
            <div className="">
              <p style={{ paddingTop: "20px" }} className="mb-4">
                Excellence Care works with individuals who have been diagnosed
                with Autism Spectrum Disorder and creates a personalised support
                system tailored to their unique abilities and challenges. We
                adopt a comprehensive approach to developing a support plan that
                accounts for the individual's wishes, choices, and preferences,
                as well as the specifics of their condition.
              </p>
              <h2
                style={{
                  marginTop: "60px",
                  width: "80%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "#F28C28",
                  textAlign: "center",
                }}
              >
                "Our clients, both current and former, have consistently
                expressed their satisfaction with our staff's dedication to
                providing compassionate and person-centered care"
              </h2>
              <Link
                to="/contact"
                className="btn btn-primary py-3 px-5 mt-4 wow zoomIn"
                data-wow-delay="0.6s"
              >
                Make Appointment
              </Link>
              <a
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  marginLeft: "15px",
                  border: "1px solid green",
                }}
                className="btn  btn-primary  py-3 px-5 mt-4 wow zoomIn"
                href="#"
              >
                WhatsApp
                <i
                  style={{
                    color: "green",
                    marginLeft: "10px",
                    fontSize: "20px",
                  }}
                  className="fab fa-whatsapp fw-normal"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Autism;
