import { Fragment, useState, useEffect } from "react";
import about from "../img/resnew.jpg";

const Covid = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 990) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.performance) {
      handleResize();

      if (performance.navigation.type == 1) {
        //     window.addEventListener("beforeunload", alertUser);
        return () => {
          window.addEventListener("resize", handleResize);

          //         window.removeEventListener("beforeunload", alertUser);
        };
      }
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
        }}
        class="container-fluid bg-primary py-5  mb-5"
        className="maisbg"
      >
        <div class="row py-3">
          <div class="col-12 text-center">
            <h1
              style={{
                marginTop: "90px",
              }}
              class="display-3 text-white animated zoomIn"
            >
              {" "}
              covid 19 Updates{" "}
            </h1>
            <a href="" class="h4 text-white">
              Home
            </a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h4 text-white">
              covid19 Updates{" "}
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          top: !isMobile ? "100px" : "0px",
          marginBottom: !isMobile ? "100px" : "50px",
        }}
        className="container-fluid py-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        {/* container1 */}

        <div className="container">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title mb-4">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  covid19 Updates{" "}
                </h5>
                <h1 className="display-5 mb-0">
                  {" "}
                  Staying Safe in Uncertain Times: Our COVID-19 Response
                </h1>
              </div>

              <p className="mb-4">
                As the COVID-19 situation continues to evolve, we are taking
                steps to ensure that we are prepared to provide the best
                possible care and support to our clients, while also minimizing
                the risk of infection and transmission.
                <br />
                <br />
                We are closely monitoring the advice and guidance of health
                authorities and government agencies, and we are taking proactive
                measures to prevent the spread of the virus. This includes:
                <br />
                <br />
                • Providing our staff with ongoing training and resources on
                infection control and prevention
                <br />
                <br />
                • Conducting regular health screenings for our clients and staff
                <br />
                <br />
                • Encouraging frequent hand hygiene and respiratory etiquette
                <br />
                <br />
                • Increasing our cleaning and disinfection protocols
                <br />
                <br />
                • Implementing social distancing measures, including remote work
                where possible
                <br />
                <br />
                • Providing personal protective equipment (PPE) to our staff as
                needed
                <br />
                <br />
                We are also implementing flexible and adaptable care plans that
                take into account the changing needs and circumstances of our
                clients. We recognize that this is a challenging time for many
                people, and we are committed to providing compassionate and
                high-quality care that meets the unique needs and preferences of
                each individual.
                <br />
                <br />
                We will continue to closely monitor the situation and adjust our
                approach as needed to ensure the safety and well-being of our
                clients and staff. We thank you for your cooperation and
                understanding during this time.
                <br />
                If you have any questions or concerns, please do not hesitate to
                contact us.
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Covid;
